import React, { useEffect, useState, useRef, useContext } from 'react';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';

import { dashboardApi } from './dashboardApi';
import LoaderContext from '../../shared/loader/loader-context';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { DASHBOARD_CONSTANTS } from './dashboard-constants';

const DashboardCalendar = () => {
	const mounted = useRef();
	const ctx = useContext(LoaderContext);
	const [calendarDate, setCalendarDate] = useState();
	const [fetchedData, setFetchedData] = useState([]);
	const [date, setDate] = useState();
	const [viewDate, setViewDate] = useState();
	const [dataToBeRendered, setDataToBeRendered] = useState({});

	useEffect(() => {
		mounted.current = true;
		return () => (mounted.current = false);
	});

	useEffect(() => {
		if (mounted.current) {
			setDate(
				`${moment().format('YYYY')}-${moment().format('MM')}-${moment().format(
					'DD'
				)}`
			);
		}
	}, []);

	useEffect(() => {
		async function fetchData(
			date = moment().format('D'),
			month = moment().format('MMM'),
			year = moment().format('YYYY')
		) {
			//console.log('fetched...', month);
			setDataToBeRendered('scroll');
			const response = await dashboardApi.getDashboardCalendar(
				date,
				month,
				year
			);
			if (mounted.current) {
				if (!response?.errors) {
					if (!!response?.length) {
						setFetchedData(response);
					} else {
						setDataToBeRendered({});
					}
				} else {
					setDataToBeRendered({});
					ctx.toasterHanlder(
						`${SHARED_CONSTANTS.ERROR}`,
						'',
						`${response.errors[0].message}`
					);
				}
			}
		}
		//console.log('date = ', date);
		!!date &&
			fetchData(
				date.split('-')[2],
				moment(date.split('-')[1], 'M').format('MMM'),
				date.split('-')[0]
			);
	}, [date]);

	useEffect(() => {
		let dataTemplate = {};
		if (!fetchedData.errors) {
			fetchedData.forEach((program) => {
				program['jobsiteprograms'].forEach((jobsiteprogram) => {
					for (var key of Object.keys(jobsiteprogram.jobsitepartsschedule)) {
						const jobsitepartsschedule = jobsiteprogram.jobsitepartsschedule;
						const site = jobsiteprogram.site[0]['name'];
						if (key === date) {
							if (!!dataTemplate[program['name']]) {
								dataTemplate[program['name']].push({
									[site]: {
										pieces: jobsitepartsschedule[date]['pieces'],
										trucks: jobsitepartsschedule[date]['truck'],
									},
								});
							} else {
								dataTemplate[program['name']] = [
									{
										[site]: {
											pieces: jobsitepartsschedule[date]['pieces'],
											trucks: jobsitepartsschedule[date]['truck'],
										},
									},
								];
							}
						}
					}
				});
			});
		}

		if (mounted.current) {
			setDataToBeRendered(dataTemplate);
		}
	}, [fetchedData]);

	const dateChangeHandler = async (event) => {
		setCalendarDate(event);
		let splittedDate = event?.toString().split(' ');
		let month = moment().month(splittedDate[1]).format('M').padStart(2, '0');
		setDate(`${splittedDate[3]}-${month}-${splittedDate[2]}`);
	};

	//This function activates when the user changes the month or year.
	const viewDateChangeHandler = async (event) => {
		setViewDate(event);
	};

	return (
		<div className='d-flex flex-column'>
			<div className='calendar-wrapper w-100'>
				<Calendar
					inline
					showWeek
					dateFormat='dd/mm/y'
					value={calendarDate}
					onChange={(e) => dateChangeHandler(e.value)}
					viewDate={viewDate}
					onViewDateChange={(e) => viewDateChangeHandler(e.value)}
				/>
			</div>
			<div className='calendar-schedule-wrapper my-3 py-2 px-3'>
				{dataToBeRendered != 'scroll' ? (
					Object.keys(dataToBeRendered).map((programName, index) => {
						return (
							<div key={index}>
								<h5 className='dashboard-calendar-program-title'>
									{programName}
								</h5>
								{dataToBeRendered[programName].map((siteName, index2) => {
									return (
										<div key={index2}>
											<ul>
												<span className='dcp-program-title'>
													{Object.keys(siteName)[0]}
												</span>
												{Object.values(siteName).map((total, index3) => {
													return (
														<div key={index3}>
															<li>Total trucks: {Math.ceil(total.trucks)}</li>
															<li>Total pieces: {total.pieces}</li>
														</div>
													);
												})}
											</ul>
										</div>
									);
								})}
							</div>
						);
					})
				) : (
					<div className='text-center'>
						<div
							className='spinner-border dashboard-calendar-program-title'
							role='status'
						>
							<span className='visually-hidden'>Loading...</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default DashboardCalendar;
