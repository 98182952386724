import React, { useState, useEffect } from 'react';
import Header from '../common/header/header';
import Sidebar from '../common/sidebar/sidebar';
import './layout.scss';
import { Outlet, useNavigate } from 'react-router-dom';

const Layout = () => {
	const [toggleClass, setToggleClass] = useState(false);

	const navigate = useNavigate();
	const toggleMenu = () => {
		setToggleClass(!toggleClass);
	};
	useEffect(() => {
		let token = localStorage.getItem('token');
		if (!token) {
			navigate('/');
			localStorage.clear();
		}
	});
	const toggle = toggleClass ? 'toggle-menu' : '';
	return (
		<>
			<div className='d-flex h-100'>
				<div className={`sidebar-wrapper ${toggle}`}>
					<Sidebar />
				</div>
				<div className='flex-grow-1 overflow-auto'>
					<div className='container-fluid h-100 d-flex flex-column px-4'>
						<div>
							{' '}
							<Header toggleMenu={toggleMenu} />
						</div>
						<div className='flex-grow-1 overflow-auto'>
							<main className='h-100'>
								<Outlet />
							</main>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Layout;
