import { createSlice } from '@reduxjs/toolkit';

export const summaryOfRunoutsSlice = createSlice({
	name: 'summaryOfRunouts',
	initialState: {
		partsFetchCompleteStatus: false,
		weekFetchCompleteStatus: false,
		partsData: [], //metalpartsreports
		weekdata: [], //summary
		weekdate: {}, //datedata
		selectedWeek: '',
	},
	reducers: {
		setPartsFetchCompleteStatus: (state, action) => {
			state.partsFetchCompleteStatus = action.payload;
		},
		setWeekFetchCompleteStatus: (state, action) => {
			state.weekFetchCompleteStatus = action.payload;
		},
		setPartsDataReducer: (state, action) => {
			state.partsData = action.payload;
		},
		setWeekDataReducer: (state, action) => {
			state.weekdata = action.payload;
		},
		setWeekDateReducer: (state, action) => {
			state.weekdate = action.payload;
		},
		setSelectedWeekReducer: (state, action) => {
			state.selectedWeek = action.payload;
		},
	},
});

export const {
	setPartsFetchCompleteStatus,
	setWeekFetchCompleteStatus,
	setPartsDataReducer,
	setWeekDataReducer,
	setWeekDateReducer,
	setSelectedWeekReducer,
} = summaryOfRunoutsSlice.actions;
export default summaryOfRunoutsSlice.reducer;
