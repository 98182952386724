import { api } from "../../shared/lib/api";

class allUsersApi {
    async getUsers(){
        return await api.get(`api/v1.0/tiffin/list/users/`);
    }
    async registerUser(data){
        return await api.post(`api/v1.0/tiffin/register/`, data);
    }
    async updateUser(data){
        return await api.put(`api/v1.0/tiffin/list/users/`, data);
    }
    async deleteUser(id){
        let data = {
            "id":id
        }
        return await api.delete(`api/v1.0/tiffin/list/users/`, data);
    }
}

export const usersApi = new allUsersApi();