import { Dialog } from 'primereact/dialog';
import { useState } from 'react';


const Modal = (props) => {
    const [modaldisplay, setModalDisplay] = useState(props.modaldisplay);
    const onHide = () => {
        setModalDisplay(false);
        props.onmodalClose();
    }
    return ( 
        <>
            <Dialog className={props.className} header={props.headerLabel} draggable={false} visible={modaldisplay} onHide={onHide} footer={props.renderFooter}>
                {props.children}
            </Dialog>
        </>
     );
}
 
export default Modal;