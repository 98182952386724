import React, { useContext, useEffect, useState } from 'react';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import AddUser from './addUser';
import { usersApi } from './usersApi';
import { Button } from 'primereact/button';
import Modal from '../../shared/components/modal';
import LoaderContext from '../../shared/loader/loader-context';
import { USER_CONSTANTS } from './usersConstant';

function UsersList() {
	let isAdmin = localStorage.getItem('isAdmin');
	isAdmin = typeof isAdmin === 'string' ? JSON.parse(isAdmin) : isAdmin;
	const ctx = useContext(LoaderContext);
	const [userModal, setUserModal] = useState(false); //show Add Modal

	const [showDeleteModal, setShowDeleteModal] = useState(false); // show/hide delete Modal
	const [users, setUsers] = useState([]); //to set holidays to list in table
	const [selectedUsers, setSelectedUsers] = useState(); //holiday selected to update/delete

	const [buttonDisable, setButtonDisable] = useState(false);

	// to get all users to list
	const fetchUsers = async () => {
		ctx.loadingHandler(true);
		const response = await usersApi.getUsers();
		ctx.loadingHandler(false);
		if (typeof response != 'undefined') {
			if (!response.errors) {
				if (response?.Response) {
					setUsers(response.Response);
					//console.log({vvv:response.Response})
				} else {
					setUsers([]);
				}
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.SOMETHING_WENT_WRONG}`
			);
		}
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	const holidayModalCloseHandler = () => {
		setUserModal(false);
		fetchUsers();
	};

	// to display delet modal
	const showDeleteModalHanlder = (users) => {
		setShowDeleteModal(true); // to show modal
		//console.log("holiday", users);
		setSelectedUsers(users);
	};

	//to delete selected holiday
	const deleteHolidayHandler = async (e) => {
		e.preventDefault();
		setButtonDisable(true);
		ctx.loadingHandler(true);
		const response = await usersApi.deleteUser(selectedUsers.id);
		ctx.loadingHandler(false);
		if (!response.errors) {
			fetchUsers(); //fetch holidays after delete
			setShowDeleteModal(false); //close modal on delete
			setSelectedUsers();
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.ONDELETE_MSG}`
			);
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		}
		//console.log("delete holiday response", response);
		setButtonDisable(false);
	};

	//Delete Modal Footer
	const renderDeleteModalFooter = () => {
		return (
			<div>
				<Button
					label={SHARED_CONSTANTS.ONDELETE_REJECT}
					onClick={() => setShowDeleteModal(false)}
					className='btn btn-secondary'
				/>
				<Button
					label={SHARED_CONSTANTS.ONDELETE_CONFIRM}
					onClick={(e) => deleteHolidayHandler(e)}
					autoFocus
					className='btn btn-yellow'
					disabled={buttonDisable}
				/>
			</div>
		);
	};

	// showUsers Edit
	const UsersEditHandler = (user) => {
		// setSelectedJobSite(jobsite);/
		setSelectedUsers(user);
		// //console.log("passing jobsite", jobsite)
		setUserModal(true);
	};

	// Close Jobsite Add/Edit Modal
	const userModalCloseHandler = () => {
		setUserModal(false);
	};

	const addNewUser = () => {
		setSelectedUsers();
		setUserModal(true);
	};

	return (
		<>
			{/* delete Modal */}
			{showDeleteModal && (
				<Modal
					className={'custom-modal'}
					onmodalClose={() => setShowDeleteModal(false)}
					modaldisplay={showDeleteModal}
					renderFooter={renderDeleteModalFooter()}
					headerLabel='Delete Holiday'
				>
					<p>{USER_CONSTANTS.DELETE_MSG}</p>
				</Modal>
			)}

			<div className='d-flex align-items-start flex-wrap'>
				<div className='page-header d-flex justify-content-between align-items-center py-2 flex-wrap pe-3 table-content'>
					<div className='page-title me-4'>{USER_CONSTANTS.MODULE_NAME}</div>
					<div>
						{
							<button
								className='btn btn-outline-primary btn-sm'
								onClick={addNewUser}
							>
								{SHARED_CONSTANTS.ADD_BTN}
							</button>
						}
					</div>
					<div className='w-100'>
						<div className='table-responsive mt-2 custom-data-tables'>
							<table className='table'>
								<thead>
									<tr>
										<th>#</th>
										<th>{USER_CONSTANTS.FIRST_NAME_LABEL}</th>
										<th>{USER_CONSTANTS.LAST_NAME_LABEL}</th>
										<th>{USER_CONSTANTS.EMAIL_LABEL}</th>
										<th>{USER_CONSTANTS.ROLE_LABEL}</th>
										{isAdmin && (
											<th className='text-end action-column'>
												{SHARED_CONSTANTS.ACTION_LABEL}
											</th>
										)}
									</tr>
								</thead>
								<tbody>
									{users?.length === 0 && (
										<tr>
											<td colSpan='4'>{USER_CONSTANTS.NO_DATA_INFO}</td>
										</tr>
									)}
									{users?.map((holiday, index) => (
										<tr key={holiday.id}>
											<td>{index + 1}</td>
											<td>{holiday.first_name}</td>
											<td>{holiday.last_name}</td>
											<td>{holiday.email}</td>
											<td>{holiday?.role}</td>
											{isAdmin && (
												<td className='text-end'>
													<span
														className='me-3 pointer'
														title='Edit'
														onClick={() => UsersEditHandler(holiday)}
													>
														<i className='bi bi-pencil fs-6'></i>
													</span>

													<span
														title='Delete'
														className='pointer'
														onClick={() => showDeleteModalHanlder(holiday)}
													>
														<i className='bi bi-trash3 fs-6'></i>
													</span>
												</td>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className='form-wrapper py-2 px-2'>
					{userModal ? (
						<AddUser
							users={selectedUsers}
							onFetch={fetchUsers}
							onCancel={userModalCloseHandler}
						/>
					) : null}
				</div>
			</div>
		</>
	);
}

export default UsersList;
