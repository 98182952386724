export const SHIPPING_PLAN_CONSTANTS = {
    MODULE_NAME: "Shipping Plan",
    SITE_CODE: "Site Code",
    PROGRAMS: "Programs",
    PROGRAM_LBL: "Program",
    PROGRAM: "Program*",

    JOBSITE_LBL: "Job Site",
    JOBSITE: "Job Site*",
    LOCATION_LBL: "Location*",
    LOCATION: "Location",
    TEMPLATE_LBL: "Template",
    TRANSIT_LBL: "Transit Time (days)*",
    TRANSIT: "Transit Time (days)",
    TRANIST_DAYS: "(days)",
    MILES_LBL: "Miles*",
    MILES: "Miles",
    NO_DATA_INFO: "No Shipping plan found.",
    UPDATE_MSG: "Shipping plan updated.",
    NEW_PLAN_ADDED_MSG: "New Shipping plan added.",
    DELETE_MSG: "Are you sure you want to delete the Shipping Plan?",
    DELETE_HDR: "Delete Shipping Plan"
}