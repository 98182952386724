import React, { useContext, useEffect, useState } from "react";
import { SHARED_CONSTANTS } from "../../shared/shared-constants";
import AddEmailDetail from "./AddEmailDetail";
import { emailApi } from "./emailsApi";
import { Button } from "primereact/button";
import Modal from "../../shared/components/modal";
import LoaderContext from "../../shared/loader/loader-context";
import { EMAIL_CONSTANTS } from "./emailConstant";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate, useParams } from "react-router-dom";
function EmailGroupDetail() {
    const navigate = useNavigate();
    const {id} = useParams()
    let isAdmin = localStorage.getItem("isAdmin");
    isAdmin = typeof isAdmin === "string" ? JSON.parse(isAdmin) : isAdmin;
    const ctx = useContext(LoaderContext);
    const [emailDetailModal, setEmailDetailModal] = useState(false); //show Add Modal
    const [showDeleteModal, setShowDeleteModal] = useState(false); // show/hide delete Modal
    const [emailDetail, setEmailDetail] = useState([]); //to set holidays to list in table
    const [selectedEmailDetail, setSelectedEmailDetails] = useState(); //holiday selected to update/delete
    const [buttonDisable, setButtonDisable] = useState(false);

     // to get all users to list
  const fetchEmailDetails = async () => {
    ctx.loadingHandler(true);
    const response = await emailApi.getEmailDetails(id);
    ctx.loadingHandler(false);
    if (!response.errors) {
      setEmailDetail(response);
      //console.log({ressssssss:response})
    } else {
      ctx.toasterHanlder(
        `${SHARED_CONSTANTS.ERROR}`,
        "",
        `${response.errors[0].message}`
      );
    }
  };

  useEffect(() => {
    fetchEmailDetails();
  }, []);

    const onIndexTemplate = (data, props) => {
        return props.rowIndex + 1;
      };
      const editEmailDetailsGroup = (rowData, event) => {
        setEmailDetailModal(true);
        setSelectedEmailDetails(rowData);
        // navigate(`/email/${rowData?.id}`);
      };

      const emailModalCloseHandler = () => {
        //   fetchEmail()
        //console.log('callledddd')
        setEmailDetailModal(false);
        // setEmail();
        setSelectedEmailDetails()
      };

      const deleteMethod = (rowData, event) => {
        setShowDeleteModal(true);
        setSelectedEmailDetails(rowData);
      };

        //to delete selected holiday
  const deleteEmail = async (e) => {
    let data = selectedEmailDetail;
    data.status = "inactive";
    e.preventDefault();
    setButtonDisable(true);
    ctx.loadingHandler(true);
    const response = await emailApi.editEmailGroupDetails(data, selectedEmailDetail?.id);
    ctx.loadingHandler(false);
    if (!response.errors) {
      fetchEmailDetails(); //fetch holidays after delete
      setShowDeleteModal(false); //close modal on delete
      setSelectedEmailDetails();
      setButtonDisable(false);

      ctx.toasterHanlder(
        `${SHARED_CONSTANTS.ERROR}`,
        "",
        `${SHARED_CONSTANTS.ONDELETE_MSG}`
      );
    } else {
      ctx.toasterHanlder(
        `${SHARED_CONSTANTS.ERROR}`,
        "",
        `${response.errors[0].message}`
      );
    }
  };

      
  //Delete Modal Footer
  const renderDeleteModalFooter = () => {
    return (
      <div>
        <Button
          label={SHARED_CONSTANTS.ONDELETE_REJECT}
          onClick={() => setShowDeleteModal(false)}
          className="btn btn-secondary"
        />
        <Button
          label={SHARED_CONSTANTS.ONDELETE_CONFIRM}
          onClick={(e) => deleteEmail(e)}
          autoFocus
          className="btn btn-yellow"
          disabled={buttonDisable}
        />
      </div>
    );
  };
    

      const actionBodyTemplate = (rowData) => {
        return (
          <>
            {isAdmin && (
              <div className="d-flex">
                <div
                  className="me-3 pointer"
                  title="Edit"
                  onClick={(event) => editEmailDetailsGroup(rowData, event)}
                >
                  <i className="bi bi-pencil fs-6"></i>
                </div>
                <div
                  title="Delete pointer"
                  onClick={(event) => deleteMethod(rowData, event)}
                >
                  <i className="bi bi-trash3 fs-6"></i>
                </div>
              </div>
            )}
          </>
        );
      };

      const addNew =()=>{
          setEmailDetailModal(true);
          setSelectedEmailDetails()
      }
  return (
   <>
    {showDeleteModal && (
        <Modal
          className={"custom-modal"}
          onmodalClose={() => setShowDeleteModal(false)}
          modaldisplay={showDeleteModal}
          renderFooter={renderDeleteModalFooter()}
          headerLabel="Delete Email"
        >
          <p>{EMAIL_CONSTANTS.DELETE_MSG}</p>
        </Modal>
      )}
      <div className="d-flex align-items-start flex-wrap">
   
        <div>
          {isAdmin && (
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={() => addNew()}
            >
              {SHARED_CONSTANTS.ADD_BTN}
            </button>
          )}
        </div>
        <div className="table-responsive">
          <DataTable
            value={emailDetail}
            paginator
            className="custom-data-tables"
            rows={10}
            // header={header}
            // filters={filters}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[15, 25, 50]}
            dataKey="id"
            let-i="rowIndex"
            rowHover
            filterDisplay="menu"
            responsiveLayout="scroll"
            emptyMessage="No Programs Found."
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            // onRowClick={navigateToEmailDetails}
            // selectionMode="single" selection={selectedProgram} onSelectionChange={e => navigateProgramDetailsHanlder(e.value)}
          >
            <Column field="Index" header="#" body={onIndexTemplate}></Column>
            {/* <Column field="group_name" header="Group Name"></Column> */}
            <Column field="email" header="Email" sortable={true}></Column>
            {isAdmin && (
              <Column header="Action" body={actionBodyTemplate}></Column>
            )}
          </DataTable>
        </div>

        <div className="form-wrapper py-2 px-2">
          {emailDetailModal ? (
            <AddEmailDetail
              email={selectedEmailDetail}
              onFetch={fetchEmailDetails}
              onCancel={emailModalCloseHandler}
            />
          ) : null}
        </div>
      </div>
   </>
  )
}

export default EmailGroupDetail