import { NavLink } from 'react-router-dom';
import moment from 'moment';

import GraphComponent from './graphcomponent';
import DashboardCardWidget from './dashboard-card-widget-component';
import DashboardPrograms from './dashboard-programs';
import DashboardCalendar from './dashboard-calendar';

const Dashboard = () => {
	return (
		<div className='d-flex'>
			<div className='row w-100'>
				<div className='col-xxl-9 col-xl-8 col-lg-12'>
					<div className='row'>
						<div className='col-md-12'>
							<h3 className='dashboard-title'>
								Dashboard Overview -{' '}
								<span className='small text-gray'>
									{moment().format('MM-D-YYYY')}
								</span>
							</h3>
							<div className='row mt-3'>
								<div className='col-xxl-5 col-xl-6 col-lg-12'>
									<h6>Program Completion</h6>
									<GraphComponent />
								</div>
								<div className='col-xxl-7 col-xl-6 col-lg-12'>
									<DashboardCardWidget />
								</div>
							</div>
						</div>
					</div>
					<div className='row mt-3'>
						<div className='col-md-12'>
							<DashboardPrograms />
						</div>
					</div>
				</div>
				<div className='col-xxl-3 col-xl-4 col-lg-12'>
					<div className='d-flex justify-content-between mb-2'>
						<h3 className='dashboard-title'>My Calendar</h3>
						<NavLink to='/my-calendar'>
							<button className='btn btn-outline-primary btn-sm ms-auto'>
								View Full Schedule
							</button>
						</NavLink>
					</div>
					<div className='p-2 gray-bg'>
						<DashboardCalendar />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
