const PASSWORD_MIN_LENGTH = 6;
export const FORGOTPASSSWORD_CONSTANTS = {
	FORGOT_PASSWORD: 'Forgot Password',
	PASSWORD: 'Password',
	CONFIRM_PASSWORD: 'Confirm Password',
	MUST_SPECIFY_PASSWORD: 'Please enter the password',
	PASSWORD_MIN_LENGTH: PASSWORD_MIN_LENGTH,
	PASSWORD_MIN_LENGTH_MSG: `The Password must have at least ${PASSWORD_MIN_LENGTH} characters.`,
	PASSWORDS_DONT_MATCH: 'The Passwords do not match.',
};
