import React, { useContext, useEffect, useState } from 'react';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import AddEmail from './AddEmail';
import { emailApi } from './emailsApi';
import { Button } from 'primereact/button';
import Modal from '../../shared/components/modal';
import LoaderContext from '../../shared/loader/loader-context';
import { EMAIL_CONSTANTS } from './emailConstant';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';

function EmailList() {
	const navigate = useNavigate();
	let isAdmin = localStorage.getItem('isAdmin');
	isAdmin = typeof isAdmin === 'string' ? JSON.parse(isAdmin) : isAdmin;
	const ctx = useContext(LoaderContext);
	const [emailModal, setEmailModal] = useState(false); //show Add Modal
	const [showDeleteModal, setShowDeleteModal] = useState(false); // show/hide delete Modal
	const [email, setEmail] = useState([]); //to set holidays to list in table
	const [selectedEmail, setSelectedEmail] = useState(); //holiday selected to update/delete
	const [buttonDisable, setButtonDisable] = useState(false);

	// to get all users to list
	const fetchEmail = async () => {
		ctx.loadingHandler(true);
		const response = await emailApi.getEmailGroup();
		ctx.loadingHandler(false);
		if (typeof response != 'undefined') {
			if (!response.errors) {
				setEmail(response);
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.SOMETHING_WENT_WRONG}`
			);
		}
	};

	useEffect(() => {
		navigate('/email');
		fetchEmail();
	}, []);

	//to delete selected holiday
	const deleteEmail = async (e) => {
		//console.log({ e: e.target.value, val: selectedEmail?.id });
		let data = selectedEmail;
		data.status = 'inactive';
		e.preventDefault();
		setButtonDisable(true);
		ctx.loadingHandler(true);
		const response = await emailApi.editEmailGroup(data, selectedEmail?.id);
		ctx.loadingHandler(false);
		if (!response.errors) {
			fetchEmail(); //fetch holidays after delete
			setShowDeleteModal(false); //close modal on delete
			setSelectedEmail();
			setButtonDisable(false);

			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.ONDELETE_MSG}`
			);
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		}
	};

	//Delete Modal Footer
	const renderDeleteModalFooter = () => {
		return (
			<div>
				<Button
					label={SHARED_CONSTANTS.ONDELETE_REJECT}
					onClick={() => setShowDeleteModal(false)}
					className='btn btn-secondary'
				/>
				<Button
					label={SHARED_CONSTANTS.ONDELETE_CONFIRM}
					onClick={(e) => deleteEmail(e)}
					autoFocus
					className='btn btn-yellow'
					disabled={buttonDisable}
				/>
			</div>
		);
	};

	const navigateToEmailDetails = (e) => {
		//console.log({eee:e})
		if (e.data.id) {
			navigate(`/email/details/${e.data.id}`);
		}
	};

	const emailModalCloseHandler = () => {
		//   fetchEmail()
		setEmailModal(false);
		// setEmail();
		setSelectedEmail();
	};

	const onIndexTemplate = (data, props) => {
		return props.rowIndex + 1;
	};

	const editEmailGroup = (rowData, event) => {
		event.stopPropagation();
		setEmailModal(true);
		setSelectedEmail(rowData);
		// navigate(`/email/${rowData?.id}`);
	};

	const deleteMethod = (rowData, event) => {
		event.stopPropagation();
		setShowDeleteModal(true);
		setSelectedEmail(rowData);
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<>
				{isAdmin && (
					<div className='d-flex'>
						<div
							className='me-3 pointer'
							title='Edit'
							onClick={(event) => editEmailGroup(rowData, event)}
						>
							<i className='bi bi-pencil fs-6'></i>
						</div>
						<div
							title='Delete pointer'
							onClick={(event) => deleteMethod(rowData, event)}
						>
							<i className='bi bi-trash3 fs-6'></i>
						</div>
					</div>
				)}
			</>
		);
	};

	return (
		<>
			{showDeleteModal && (
				<Modal
					className={'custom-modal'}
					onmodalClose={() => setShowDeleteModal(false)}
					modaldisplay={showDeleteModal}
					renderFooter={renderDeleteModalFooter()}
					headerLabel='Delete Email Group'
				>
					<p>{EMAIL_CONSTANTS.DELETE_MSG}</p>
				</Modal>
			)}
			<div className='d-flex align-items-start flex-wrap flex-column'>
				<div className='d-flex flex-wrap w-100'>
					<div className='table-content'>
						<div className='page-header d-flex justify-content-between align-items-center py-2 flex-wrap flex-grow-1'>
							<div className='page-title me-4'>Email</div>
							<div>
								{isAdmin && (
									<button
										className='btn btn-outline-primary btn-sm'
										onClick={() => setEmailModal(true)}
									>
										{SHARED_CONSTANTS.ADD_BTN}
									</button>
								)}
							</div>
						</div>
						<div className='table-responsive'>
							<DataTable
								value={email}
								paginator
								className='custom-data-tables'
								rows={10}
								// header={header}
								// filters={filters}
								paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
								rowsPerPageOptions={[15, 25, 50]}
								dataKey='id'
								let-i='rowIndex'
								// rowHover
								filterDisplay='menu'
								responsiveLayout='scroll'
								emptyMessage='No Programs Found.'
								currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
								onRowClick={navigateToEmailDetails}
								// selectionMode="single"
							>
								<Column
									field='Index'
									header='#'
									body={onIndexTemplate}
								></Column>
								<Column field='group_name' header='Group Name'></Column>
								<Column field='email' header='Email' sortable={true}></Column>
								{isAdmin && (
									<Column
										field='action'
										header='Action'
										body={actionBodyTemplate}
									></Column>
								)}
							</DataTable>
						</div>
					</div>

					<div className='form-wrapper py-2 px-2'>
						{emailModal ? (
							<AddEmail
								email={selectedEmail}
								onFetch={fetchEmail}
								onCancel={emailModalCloseHandler}
							/>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
}

export default EmailList;
