import './login.scss';
import LOGINLOGO from '../../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';
import { LOGIN_CONSTANTS } from './login-constant';
import { useForm } from 'react-hook-form';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { loginApi } from './loginApi';
import { useContext, useEffect } from 'react';
import LoaderContext from '../../shared/loader/loader-context';
import { fileUploadNotificationContext } from '../../shared/file-upload-notification/fileUploadNotificationContext';

const Login = () => {
	const ctx = useContext(LoaderContext);
	const { clearAllFileUploadNotification } = useContext(
		fileUploadNotificationContext
	);

	useEffect(() => {
		clearAllFileUploadNotification();
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		getValues,
	} = useForm(); // to set name and description and validate using react hook form
	// use Input hook for email
	let navigate = useNavigate();

	const onSubmit = async (data) => {
		//console.log('login Data', data);
		const response = await loginApi.login(data);
		// reset input value in email and password
		// set cookie
		if (response && response.status !== 0) {
			//console.log({ response });
			if (!response.errors) {
				localStorage.setItem('token', response.access);
				const userResponse = await loginApi.checkUserRole();
				//console.log({ userResponse });
				if (!userResponse.errors) {
					reset();
					let isAdmiFlag = userResponse[0]?.is_admin;
					let email = userResponse[0]?.email;

					localStorage.setItem('isAdmin', isAdmiFlag);
					localStorage.setItem('username', email);

					const access = {
						refresh: response.refresh,
					};
					document.cookie = JSON.stringify(access);
					navigate('/dashboard');
				}
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.FAILED_TO_FETCH}`
			);
		}
		//console.log('Login Response', response);
	};

	const handle_forgot_password = async (e) => {
		e.preventDefault();
		const email = getValues('email');
		if (!!email) {
			const response = await loginApi.RequestPasswordResetEmail({
				email: email,
			});
			//console.log('handle_forgot_password = ', response);
			if (response.Status == true) {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.SUCCESS}`,
					'',
					`${LOGIN_CONSTANTS.CHECK_PASSWORD_RESET_EMAIL}`
				);
			} else if (!!response.Error) {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response?.Error}`
				);
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${SHARED_CONSTANTS.UNEXPECTED_ERROR}`
				);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${LOGIN_CONSTANTS.PLEASE_ENTER_EMAIL}`
			);
		}
		//console.log('handle_forgot_password clicked = ', email);
	};

	return (
		<>
			<div className='login-wrapper h-100 w-100 d-flex'>
				<div className='flex-grow-1 login-bg'></div>
				<div className='login-form-fields  d-flex flex-column justify-content-center align-items-center'>
					<div className='login-log mb-4'>
						<img src={LOGINLOGO} className='img-fluid' alt='login-cover' />
					</div>
					<form onSubmit={handleSubmit(onSubmit)} className='w-100'>
						<div className='mb-3'>
							<label htmlFor='email'>{LOGIN_CONSTANTS.USRNAME_LBL}</label>
							<input
								type='text'
								id='email'
								{...register('email', {
									required: true,
									// pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
								})}
								className='form-control'
							></input>
							{errors?.email?.type === 'required' && (
								<p className='error-text'>
									{SHARED_CONSTANTS.REQUIRED_ERR_MSG}
								</p>
							)}
							{errors?.email?.type === 'pattern' && (
								<p className='error-text'>{LOGIN_CONSTANTS.USRNAME_ERR_MSG}</p>
							)}
						</div>
						<div className='mb-1'>
							<label htmlFor='password'>{LOGIN_CONSTANTS.PASSWORD_LBL}</label>
							<input
								type='password'
								className='form-control'
								id='password'
								{...register('password', { required: true })}
							></input>
							{errors?.password?.type === 'required' && (
								<p className='error-text'>
									{SHARED_CONSTANTS.REQUIRED_ERR_MSG}
								</p>
							)}
						</div>
						<div className='d-flex justify-content-xl-between mb-4 align-items-center'>
							<div></div>
							<div>
								<a href='' onClick={handle_forgot_password}>
									{LOGIN_CONSTANTS.FRGT_PSWD}
								</a>
							</div>
						</div>
						<button className='btn btn-yellow text-uppercase btn-lg w-100'>
							{LOGIN_CONSTANTS.LOGIN}
						</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default Login;
