export const MY_CALENDAR_CONSTANTS = {
    MODULE_NAME: "Schedule",
    SELECT_PROGRAM: "Select Program",
    SELECT_EMAIL: "Select Email",
    SELECT_JOBSITE: "Select Jobsite",
    JOBSITE: "Job Site:",
    PART_NO: "Part Number",
    NO_OF_PARTS: "No. of Parts",
    TRUCKS_NEEDED: "Trucks Needed",
    PROGRAM_NAME: "Program Name",
    EMAIL_SENT: "Email sent successfully.",
    TRUCKS: "Trucks",
    CUSTOM_SHIP_DATE: "Custom Ship Date",
    SHIP_DATE: "Ship Date",
    SALESORDER: "Sales Order Numbers:"
}