import './header.scss';
import { useNavigate } from 'react-router-dom';
const Header = (props) => {
	let userName = localStorage.getItem('username') || 'user';
	let navigate = useNavigate();

	const logout = () => {
		localStorage.clear();
		navigate('/login');
	};
	return (
		<div className='d-flex py-2'>
			<div onClick={props.toggleMenu}>
				<i className='bi bi-list fs-4 pointer'></i>
			</div>
			<div className='ms-auto d-flex align-items-center'>
				{/* <div className="relative notification me-4">
          <i className="bi bi-bell fs-4"></i>
          <div className="badge badge-danger">9</div>
        </div> */}
				<div className='d-flex align-items-center'>
					<div className='user-pic'>
						{userName.substring(0, 2).toUpperCase()}
					</div>
					<div className='user-name mx-2'>{userName}</div>
					<div className='user-logout' onClick={logout}>
						<i className='bi bi-box-arrow-right fs-6'></i>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
