import React, { useContext, useEffect, useState } from 'react';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { programApi } from '../program/programApi';
import AddShippingPlanComponent from './add-shipping-plan-component';
import { SHIPPING_PLAN_CONSTANTS } from './shipping-plan-constant';
import Modal from '../../shared/components/modal';
import { Button } from 'primereact/button';
import { shippingPlanApi } from './shippingPlanApi';
import LoaderContext from '../../shared/loader/loader-context';

const ShippingPlan = () => {
	let isAdmin = localStorage.getItem('isAdmin');
	isAdmin = typeof isAdmin === 'string' ? JSON.parse(isAdmin) : isAdmin;
	const ctx = useContext(LoaderContext);
	const [shippingPlans, setShippingPlans] = useState([]); //to show list of shipping plans in table
	const [addShippingPlanModal, setaddShippingPlanModal] = useState(false); //to show/hide add/edit shipping plan modal
	const [selectedShippingPlan, setSelectedShippingPlan] = useState(); //set the selected shipping plan to edit/ delete
	const [showDeleteModal, setShowDeleteModal] = useState(false); // show/hide delete Modal
	const [buttonDisable, setButtonDisable] = useState(false);

	//fetch list of shipping plans for table view
	const fetchAllShippingPlans = async () => {
		ctx.loadingHandler(true);
		const response = await programApi.getPrograms(); // get programs
		ctx.loadingHandler(false);
		if (typeof response != 'undefined') {
			if (!response.errors) {
				let _shippingPlans = [];
				response?.map((program) => {
					//mapping through programs to get array of jobsite programs
					program?.JobsitePrograms.map((jobsiteProgram) => {
						//mapping through array of jobsites to get jobsite objects
						//console.log("Jobsite", jobsiteProgram);
						jobsiteProgram.program_name = program.name; //program name need for each jobsite related to program
						jobsiteProgram.program_id = program.id; //program id needed for each jobsite realted to the program
						if (jobsiteProgram.location) {
							_shippingPlans.push(jobsiteProgram); //pushing all the jobsites
						}
					});
				});
				//console.log("_shipping Plan", _shippingPlans);
				setShippingPlans(_shippingPlans);
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.SOMETHING_WENT_WRONG}`
			);
		}
	};
	//shwo delete shipping plan modal
	const deleteShippingPlanModalHandler = (shippingPlan) => {
		setSelectedShippingPlan(shippingPlan); //set selected shipping plan
		setShowDeleteModal(true); //set modal to true
	};
	const deleteShippingPlanHandler = async () => {
		setButtonDisable(true);
		//console.log('selected shipping Plan', selectedShippingPlan);
		let data = {
			location: null,
			miles: null,
			transit: null,
		};
		ctx.loadingHandler(true);
		const response = await shippingPlanApi.postOrUpdateShippingPlan(
			selectedShippingPlan.id,
			data
		);
		ctx.loadingHandler(false);
		//console.log("del", response);
		if (!response.errors) {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.SUCCESS}`,
				'',
				`${SHARED_CONSTANTS.ONDELETE_MSG}`
			);
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		}
		fetchAllShippingPlans(); //fetch all shipping plans after delete
		setShowDeleteModal(false); //close modal on delete
		setSelectedShippingPlan(); // after deleting set selected shipping Plan to null
		setButtonDisable(false);
	};
	//show edit shipping plan modal
	const editShippingPlanHandler = (shippingPlan) => {
		setSelectedShippingPlan(shippingPlan); //set selected shipping to display in input value
		setaddShippingPlanModal(true); //set modal to true
	};
	//show add shipping plan modal
	const addShippingPlanHandler = () => {
		setSelectedShippingPlan(); //set selected shipping plan empty as entering new data
		setaddShippingPlanModal(true); //set modal to true
		//console.log("Add new", selectedShippingPlan);
	};

	useEffect(() => {
		fetchAllShippingPlans(); //fetch all shipping plans on intial render
	}, []);

	//onModalHide
	const onModalHide = () => {
		setShowDeleteModal(false); //hide delete modal
	};

	//Delete Modal Footer
	const renderDeleteModalFooter = () => {
		return (
			<div>
				<Button
					label={SHARED_CONSTANTS.ONDELETE_REJECT}
					onClick={() => onModalHide()}
					className='btn btn-secondary'
				/>
				<Button
					label={SHARED_CONSTANTS.ONDELETE_CONFIRM}
					onClick={deleteShippingPlanHandler}
					autoFocus
					className='btn btn-yellow'
					disabled={buttonDisable}
				/>
			</div>
		);
	};

	return (
		<>
			{showDeleteModal && (
				<Modal
					className={'custom-modal'}
					onmodalClose={() => setShowDeleteModal(false)}
					modaldisplay={showDeleteModal}
					renderFooter={renderDeleteModalFooter()}
					headerLabel={SHIPPING_PLAN_CONSTANTS.DELETE_HDR}
				>
					<p>{SHIPPING_PLAN_CONSTANTS.DELETE_MSG}</p>
				</Modal>
			)}

			<div className='d-flex align-items-start flex-wrap'>
				<div className='page-header d-flex justify-content-between align-items-center py-2 flex-wrap pe-3 table-content'>
					<div className='page-title me-4'>
						{SHIPPING_PLAN_CONSTANTS.MODULE_NAME}
					</div>
					<div>
						{isAdmin && (
							<button
								className='btn btn-outline-primary btn-sm'
								onClick={addShippingPlanHandler}
							>
								{SHARED_CONSTANTS.ADD_BTN}
							</button>
						)}
					</div>
					<div className='w-100'>
						<div className='table-responsive mt-2 custom-data-tables'>
							<table className='table'>
								<thead>
									<tr>
										<th>{SHIPPING_PLAN_CONSTANTS.SITE_CODE}</th>
										<th>{SHIPPING_PLAN_CONSTANTS.PROGRAM_LBL}</th>
										<th>{SHIPPING_PLAN_CONSTANTS.LOCATION}</th>
										{/* <th>{SHIPPING_PLAN_CONSTANTS.TEMPLATE_LBL}</th> */}
										<th>{SHIPPING_PLAN_CONSTANTS.TRANSIT}</th>
										<th>{SHIPPING_PLAN_CONSTANTS.MILES}</th>
										{isAdmin && (
											<th className='text-end action-column'>
												{SHARED_CONSTANTS.ACTION_LABEL}
											</th>
										)}
									</tr>
								</thead>
								<tbody>
									{shippingPlans?.length === 0 && (
										<tr>
											<td colSpan='6'>
												{SHIPPING_PLAN_CONSTANTS.NO_DATA_INFO}
											</td>
										</tr>
									)}
									{shippingPlans?.map((shippingPlan) => (
										<tr key={shippingPlan.id}>
											<td>{shippingPlan.jobsite.name}</td>
											<td>{shippingPlan.program_name}</td>
											<td>{shippingPlan.location}</td>
											{/* <td>AR-FC</td> */}
											<td>{shippingPlan.transit}</td>
											<td>{shippingPlan.miles}</td>
											{isAdmin && (
												<td className='text-end'>
													<span
														className='me-3 pointer'
														title='Edit'
														onClick={() =>
															editShippingPlanHandler(shippingPlan)
														}
													>
														<i className='bi bi-pencil fs-6'></i>
													</span>
													<span
														title='Delete'
														className='pointer'
														onClick={() =>
															deleteShippingPlanModalHandler(shippingPlan)
														}
													>
														<i className='bi bi-trash3 fs-6'></i>
													</span>
												</td>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className='form-wrapper py-2 px-2'>
					{addShippingPlanModal ? (
						<AddShippingPlanComponent
							shippingPlan={selectedShippingPlan}
							onFetch={fetchAllShippingPlans}
							onCancel={() => setaddShippingPlanModal(false)}
						/>
					) : null}
				</div>
			</div>
		</>
	);
};

export default ShippingPlan;
