import { createSlice } from '@reduxjs/toolkit';

export const ProgramSlice = createSlice({
	name: 'program',
	initialState: {
		partsData: [],
		filterDate: null,
		filterPartNumber: null,
		isDataUpdated: false,
		jobSiteId: null,
		updateSalesOrder: {},
		addSales: { clicked: false, saving: false, fetchSales: false },
	},
	reducers: {
		setPartsDataInReducer: (state, action) => {
			//console.log({action})
			state.partsData = action.payload;
		},
		setFilterDate: (state, action) => {
			//console.log({action})
			state.filterDate = action.payload;
		},
		setFilterPartNumber: (state, action) => {
			//console.log({action});
			state.filterPartNumber = action.payload;
		},
		resetFilter: (state, action) => {
			state.filterDate = null;
			state.filterPartNumber = null;
		},
		clearPartNumber: (state, action) => {
			state.filterPartNumber = null;
		},
		dataUpdated: (state, action) => {
			state.isDataUpdated = action.payload;
			// console.log('isDataUpdated', state.isDataUpdated);
		},
		setJobsiteId: (state, action) => {
			state.jobSiteId = action.payload;
		},
		// setSalesOrder: (state, action) => {
		// 	state.salesOrder = action.payload;
		// },
		setAddSales: (state, action) => {
			state.addSales = action.payload;
		},
		setUpdateSalesOrder: (state, action) => {
			state.updateSalesOrder = action.payload;
		},
	},
});

export const {
	setPartsDataInReducer,
	setFilterDate,
	setFilterPartNumber,
	resetFilter,
	clearPartNumber,
	dataUpdated,
	setJobsiteId,
	setSalesOrder,
	setAddSales,
	setUpdateSalesOrder,
} = ProgramSlice.actions;
export default ProgramSlice.reducer;
