import React, { useContext, useRef, useEffect } from 'react';
import LoaderContext from '../../shared/loader/loader-context';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { inventoryApi } from './InventoryApi';
import { INVENTORY_CONSTANTS } from './inventory-constant';
import { useFirebaseContext } from '../../shared/globalValues/globalValuesContext';
import { fileUploadNotificationContext } from '../../shared/file-upload-notification/fileUploadNotificationContext';

const InventoryUploadComponent = ({
	getInventoryUploadedList,
	getGSSUploadList,
}) => {
	let mounted = useRef();
	let isAdmin = localStorage.getItem('isAdmin');
	isAdmin = typeof isAdmin === 'string' ? JSON.parse(isAdmin) : isAdmin;
	const ctx = useContext(LoaderContext);
	const {
		addFileUploadNotification,
		TrackCeleryTask,
		setRemoveFileUploadNotificationId,
	} = useContext(fileUploadNotificationContext);
	const globalState = useFirebaseContext();
	const firebaseToken = globalState.notificationToken;
	const formData = new FormData(); //form data to send file
	const fileInput = useRef();

	//console.log('token = ', firebaseToken);

	useEffect(() => {
		mounted.current = true;
		return () => (mounted.current = false);
	});

	//set formdata on file select
	const handleselectedFile = (event) => {
		//console.log('selected file', event.target.files[0]);
		if (
			event.target.files[0].type === INVENTORY_CONSTANTS.SUPPORTED_FILE_TYPE
		) {
			//console.log('selected file name', event.target.files[0].name);
			formData.append('files', event.target.files[0]);
			//console.log('files = ', event);
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${INVENTORY_CONSTANTS.FILE_TYPE_ERROR_MSG}`
			);
			fileInput.current.value = ''; //Remove the file after upload
		}
	};

	//upload file
	const uploadHanlder = async () => {
		//console.log('formData = ', formData['files']);
		if (formData.has('files')) {
			const id = addFileUploadNotification({
				title: `${INVENTORY_CONSTANTS.FILE_UPLOAD_HEADING}`,
				backgroundColor: '#F8B400',
			});

			const response = await inventoryApi.inventoryFileUpload(
				formData,
				encodeURIComponent(firebaseToken)
			);

			if (!response.errors) {
				if (!!response.taskid) {
					TrackCeleryTask(id, response.taskid, getInventoryUploadedList);
				} else {
					ctx.toasterHanlder(
						`${SHARED_CONSTANTS.SUCCESS}`,
						`${SHARED_CONSTANTS.PROCESSING_MSG_INV}`,
						`${response?.message}`
					);
					setRemoveFileUploadNotificationId(id);
				}
			} else {
				ctx.loadingHandler(true);
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					`${SHARED_CONSTANTS.PROCESSING_MSG}`,
					`${response?.errors[0]?.message}`
				);
				ctx.loadingHandler(false);
				setTimeout(function () {
					setRemoveFileUploadNotificationId(id);
				}, 2000);
			}
			if (mounted.current) {
				fileInput.current.value = ''; //Remove the file after upload
			}
		} else {
			ctx.loadingHandler(true);
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				`${SHARED_CONSTANTS.PROCESSING_MSG}`,
				`${SHARED_CONSTANTS.CHOOSE_A_FILE}`
			);
			ctx.loadingHandler(false);
		}
	};
	//GSS Upload Handler
	const uploadGSSHanlder = async () => {
		const response = await inventoryApi.GSSUpload();
		if (response.message) {
			getGSSUploadList();
			ctx.toasterHanlder(`${SHARED_CONSTANTS.SUCCESS}`, '', response.message);
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				response.errors[0].message
			);
		}
	};

	return (
		<>
			{isAdmin && (
				<div className='d-flex flex-column'>
					{/* <h5 className='card-title'>
							{INVENTORY_CONSTANTS.UPLOAD_INVENTORY}
						</h5> */}
					<div className='d-flex justify-content-between'>
						<div className='d-flex align-items-center'>
							<div className='form-group mt-2 small'>
								<input
									type='file'
									className='form-control-file'
									id='inventory-upload'
									ref={fileInput}
									onChange={handleselectedFile}
									accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
								/>
							</div>
							<button
								className='btn btn-yellow btn-sm mt-3 me-3'
								onClick={uploadHanlder}
							>
								{INVENTORY_CONSTANTS.UPLOAD}
							</button>
						</div>
						<button
							className='btn btn-success btn-sm mt-3'
							onClick={uploadGSSHanlder}
						>
							{INVENTORY_CONSTANTS.GSS}
						</button>
					</div>

					<div className='mt-3'>
						<div className='red-clr fs-12px'>
							* {SHARED_CONSTANTS.FILE_UPLOAD_WARNING_MSG}
						</div>
					</div>
					<hr />
				</div>
			)}
		</>
	);
};
export default InventoryUploadComponent;
