import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useForm, Controller } from 'react-hook-form';
import { SHIPPING_PLAN_CONSTANTS } from './shipping-plan-constant';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { programApi } from '../program/programApi';
import { shippingPlanApi } from './shippingPlanApi';
import LoaderContext from '../../shared/loader/loader-context';

const AddShippingPlanComponent = (props) => {
	const ctx = useContext(LoaderContext);
	const {
		handleSubmit,
		formState: { errors },
		reset,
		control,
		setValue,
	} = useForm(); //set input values using react hook form useForm
	const [programs, setPrograms] = useState(); //set list of programs to be listed in dropdown
	const [program, setProgram] = useState(); // to set the selected program from dropdown
	const [jobSites, setJobsites] = useState(); //to set list of jobsites to be in dropdown
	const [selectedJobsite, setSelectedJobSite] = useState(); // to set selected jobsite from list of jobsites
	const [jobsiteError, setJobSiteError] = useState(null); // to set selected jobsite from list of jobsites

	const [close, setClose] = useState(false); // to close modal onclicking save and close

	//Fetach all programs to be listed in dropdown
	const fetchAllProgramOptions = async () => {
		ctx.loadingHandler(true);
		const response = await programApi.getPrograms();
		ctx.loadingHandler(false);
		if (!response.errors) {
			//console.log('Program in shipping', response);
			setPrograms(response);
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		}
	};
	//to get all jobsites based on selected program
	const getJobSitesHandler = async (id) => {
		ctx.loadingHandler(true);
		setProgram(id);
		const response = await programApi.getProgramDetail(id);
		ctx.loadingHandler(false);
		if (!response.errors) {
			// console.log('Jobsite on program select shipping plan', response);
			setJobsites(response.JobsitePrograms);
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		}
	};
	//set shipping plan if exist to update
	const setshippingPlanHandler = async () => {
		//console.log('Shipping plan exist', props.shippingPlan);
		if (props?.shippingPlan?.id) {
			const { location, miles, transit, id, program_id } = props.shippingPlan;
			//console.log('Shipping Plan Id', id);
			setValue('shipping_plan', {
				location: location,
				miles: miles,
				transit: transit,
			});
			setProgram(program_id);
			getJobSitesHandler(program_id);
			setSelectedJobSite(id);
		} else {
			reset();
			setProgram();
			setSelectedJobSite();
		}
	};
	// use effect to call program options on initial render
	useEffect(() => {
		fetchAllProgramOptions();
	}, []);
	//to set shipping plan if props has shipping plan data
	useEffect(() => {
		setshippingPlanHandler();
	}, [props?.shippingPlan]);

	//submit shipping plan on add/edit
	const onSubmit = async (data) => {
		//console.log(data.shipping_plan);
		if (selectedJobsite) {
			ctx.loadingHandler(true);
			const response = await shippingPlanApi.postOrUpdateShippingPlan(
				selectedJobsite,
				data.shipping_plan
			);
			ctx.loadingHandler(false);
			if (!response.errors) {
				//console.log('Response post shipping plan', response);
				if (props?.shippingPlan) {
					setJobSiteError(null);

					ctx.toasterHanlder(
						`${SHARED_CONSTANTS.SUCCESS}`,
						'',
						`${SHIPPING_PLAN_CONSTANTS.UPDATE_MSG}`
					);
				} else {
					setJobSiteError(null);
					ctx.toasterHanlder(
						`${SHARED_CONSTANTS.SUCCESS}`,
						'',
						`${SHIPPING_PLAN_CONSTANTS.NEW_PLAN_ADDED_MSG}`
					);
				}
				reset();
				setSelectedJobSite();
				setProgram();
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
		} else {
			setJobSiteError('required');
		}
		if (close) {
			props.onCancel();
		}
		props.onFetch();
	};

	const jobSiteChange = (e) => {
		setJobSiteError(null);
		setSelectedJobSite(e.value);
	};

	return (
		<div className='card'>
			<div className='card-body'>
				<h5 className='card-title'>
					{selectedJobsite
						? `${SHARED_CONSTANTS.ONUPDATE_HEADER}`
						: `${SHARED_CONSTANTS.ADD_BTN}`}{' '}
					{SHIPPING_PLAN_CONSTANTS.MODULE_NAME}
				</h5>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='mb-2'>
						<label>{SHIPPING_PLAN_CONSTANTS.PROGRAM}</label>
						<Dropdown
							value={program}
							optionLabel='name'
							optionValue='id'
							options={programs}
							onChange={(e) => getJobSitesHandler(e.value)}
							className='w-100'
							placeholder='Select a Program*'
							disabled={props?.shippingPlan}
						/>
					</div>
					<div className='mb-2'>
						<label>{SHIPPING_PLAN_CONSTANTS.JOBSITE}</label>
						<Dropdown
							value={selectedJobsite}
							optionValue='id'
							optionLabel='jobsite.name'
							onChange={(e) => jobSiteChange(e)}
							options={jobSites}
							placeholder='Select a Jobsite'
							className='w-100'
							disabled={props?.shippingPlan}
						/>
					</div>
					{jobsiteError ? (
						<p className='error-text'>{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
					) : null}

					<div className='mb-2'>
						<label>{SHIPPING_PLAN_CONSTANTS.LOCATION_LBL}</label>
						<Controller
							name='shipping_plan.location'
							control={control}
							rules={{
								required: true,
								pattern: SHARED_CONSTANTS.ASCII_ONLY,
							}}
							render={({ field }) => <InputText {...field} className='w-100' />}
							defaultValue=''
						/>
						{errors?.shipping_plan?.location?.type === 'required' && (
							<p className='error-text'>{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
						)}
						{errors?.shipping_plan?.location?.type === 'pattern' && (
							<p className='error-text'>{SHARED_CONSTANTS.INVALID_CHARACTER}</p>
						)}
						{/* {console.log(errors)} */}
					</div>

					<div className='mb-2 d-flex'>
						<div className='me-2'>
							<label>{SHIPPING_PLAN_CONSTANTS.TRANSIT_LBL}</label>
							<Controller
								name='shipping_plan.transit'
								control={control}
								rules={{
									required: true,
									pattern: SHARED_CONSTANTS.INTEGER_REGEX,
								}}
								render={({ field }) => (
									<InputText {...field} className='w-100' />
								)}
								defaultValue=''
							/>
							{errors?.shipping_plan?.transit?.type === 'required' && (
								<p className='error-text'>
									{SHARED_CONSTANTS.REQUIRED_ERR_MSG}
								</p>
							)}
							{errors?.shipping_plan?.transit?.type === 'pattern' && (
								<p className='error-text'>
									{SHARED_CONSTANTS.INVALID_CHARACTER}
								</p>
							)}
						</div>
						<div>
							<label>{SHIPPING_PLAN_CONSTANTS.MILES_LBL}</label>
							<Controller
								name='shipping_plan.miles'
								control={control}
								rules={{
									required: true,
									pattern: SHARED_CONSTANTS.DECIMAL_REGEX,
								}}
								render={({ field }) => (
									<InputText {...field} className='w-100' />
								)}
								defaultValue=''
							/>
							{errors?.shipping_plan?.miles?.type === 'required' && (
								<p className='error-text'>
									{SHARED_CONSTANTS.REQUIRED_ERR_MSG}
								</p>
							)}
							{errors?.shipping_plan?.miles?.type === 'pattern' && (
								<p className='error-text'>
									{SHARED_CONSTANTS.INVALID_CHARACTER}
								</p>
							)}
						</div>
					</div>

					<div className='d-flex flex-wrap my-3 button-wrapper'>
						<button className='btn btn-yellow mb-2' type='submit'>
							{SHARED_CONSTANTS.ONSAVE}
						</button>
						<button
							className='btn btn-success mb-2'
							type='submit'
							onClick={() => setClose(true)}
						>
							{SHARED_CONSTANTS.ONSAVE_AND_CLOSE}
						</button>
						<button className='btn btn-secondary mb-2' onClick={props.onCancel}>
							{SHARED_CONSTANTS.ONCANCEL}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};
export default AddShippingPlanComponent;
