const hostname = window.location.hostname.split(/[.|:]/);

// const LOCAL_API_ROOT = 'http://192.168.232.31:8000/';
// const LOCAL_API_ROOT_WS = 'ws://192.168.232.31:8000/';

const LOCAL_API_ROOT = 'https://parttracker.tiffinmetal.com/';
const LOCAL_API_ROOT_WS = 'ws://parttracker.tiffinmetal.com/';

export const API_ROOT =
	hostname[0] === 'localhost'
		? LOCAL_API_ROOT
		: // Staging
		hostname[0] === 'parttracker'
		? `https://${hostname[0]}.tiffinmetal.com/`
		: LOCAL_API_ROOT;
// 	: // QA
// 	hostname[1] === 'qa'
// 	? `https://${hostname[0]}.qa.tiffin-metal/`
// 	: // Stage
// 	hostname[1] === 'stage'
// 	? `https://${hostname[0]}.stage.tiffin-metal/`
// 	: // Production
// 	hostname[1] === 'prod'
// 	? `https://${hostname[0]}.prod.tiffin-metal/`
// 	: // If can't define, use Development
// 	  process.env.REACT_APP_BACKEND_HOST;

export const API_ROOT_WS =
	hostname[0] === 'localhost'
		? LOCAL_API_ROOT_WS
		: // Staging
		hostname[0] === 'parttracker'
		? `wss://${hostname[0]}.tiffinmetal.com/`
		: LOCAL_API_ROOT_WS;
