import { useEffect, useState, useRef, useContext } from 'react';
import LoaderContext from '../../shared/loader/loader-context';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { dashboardApi } from './dashboardApi';
import './dashboard-card-widget-component.scss';

const DashboardCardWidget = () => {
	let mounted = useRef();
	const ctx = useContext(LoaderContext);
	let [dashboardCard, setDashboardCard] = useState([]);

	useEffect(() => {
		mounted.current = true;
		return () => (mounted.current = false);
	});

	useEffect(async () => {
		setDashboardCard('scroll');
		const response = await dashboardApi.getDashboardCard();
		setDashboardCard(false);
		if (mounted.current) {
			if (!response?.errors) {
				setDashboardCard(response);
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
		}
	}, []);

	return (
		<div className='row'>
			<div className='col-xl-6 col-lg-3 col-sm-6'>
				<div className='card dashboard-card p-3 py-4 mb-3 card-purple custom-p2'>
					<div className='d-flex justify-content-between flex-nowrap'>
						<div className='card-icon-wrap'>
							<i className='bi bi-box2 fs-5'></i>
						</div>
						<div className='card-count'>
							{typeof dashboardCard != 'undefined' &&
								!dashboardCard.errors &&
								(dashboardCard == 'scroll' ? (
									<div className='spinner-border' role='status'>
										<span className='visually-hidden'>Loading...</span>
									</div>
								) : (
									dashboardCard?.inventoryShortage ?? ''
								))}
						</div>
					</div>
					<hr className='mt-3 mb-2 opactiy-half' />
					<div className='card-label'>Inventory shortage</div>
				</div>
			</div>
			<div className='col-xl-6 col-lg-3 col-sm-6'>
				<div className='card dashboard-card p-3 py-4 mb-3 card-orange custom-p2'>
					<div className='d-flex justify-content-between flex-nowrap'>
						<div className='card-icon-wrap'>
							<i className='bi bi-truck fs-5'></i>
						</div>
						<div className='card-count'>
							{typeof dashboardCard != 'undefined' &&
								!dashboardCard.errors &&
								(dashboardCard == 'scroll' ? (
									<div className='spinner-border' role='status'>
										<span className='visually-hidden'>Loading...</span>
									</div>
								) : (
									dashboardCard?.trucksNeeded ?? ''
								))}
						</div>
					</div>
					<hr className='mt-3 mb-2 opactiy-half' />
					<div className='card-label'>Trucks needed</div>
				</div>
			</div>
			<div className='col-xl-6 col-lg-3 col-sm-6'>
				<div className='card dashboard-card p-3 py-4 mb-3 card-blue custom-p2'>
					<div className='d-flex justify-content-between flex-nowrap'>
						<div className='card-icon-wrap'>
							<i className='bi bi-collection fs-5'></i>
						</div>
						<div className='card-count'>
							{typeof dashboardCard != 'undefined' &&
								!dashboardCard.errors &&
								(dashboardCard == 'scroll' ? (
									<div className='spinner-border' role='status'>
										<span className='visually-hidden'>Loading...</span>
									</div>
								) : (
									dashboardCard?.partsToBeShipped ?? ''
								))}
						</div>
					</div>
					<hr className='mt-3 mb-2 opactiy-half' />
					<div className='card-label'>Parts to be shipped</div>
				</div>
			</div>
			<div className='col-xl-6 col-lg-3 col-sm-6'>
				<div className='card dashboard-card p-3 py-4 mb-3 card-green custom-p2'>
					<div className='d-flex justify-content-between flex-nowrap'>
						<div className='card-icon-wrap'>
							<i className='bi bi-card-list fs-5'></i>
						</div>
						<div className='card-count'>
							{typeof dashboardCard != 'undefined' &&
								!dashboardCard.errors &&
								(dashboardCard == 'scroll' ? (
									<div className='spinner-border' role='status'>
										<span className='visually-hidden'>Loading...</span>
									</div>
								) : (
									dashboardCard?.noOfPrograms ?? ''
								))}
						</div>
					</div>
					<hr className='mt-3 mb-2 opactiy-half' />
					<div className='card-label'>No. of programs</div>
				</div>
			</div>
		</div>
	);
};
export default DashboardCardWidget;
