export const LOGIN_CONSTANTS = {
	USRNAME_LBL: 'Username',
	USRNAME_ERR_MSG: 'Please enter a valid email address.',
	PASSWORD_LBL: 'Password',
	PASSWORD_MSG: 'Please enter correct password',
	FRGT_PSWD: 'Forgot Password?',
	LOGIN: 'Login',
	REMEMBER_ME: 'Remember Me',
	CHECK_PASSWORD_RESET_EMAIL:
		'A password reset email has been sent to the registered email address',
	PLEASE_ENTER_EMAIL: 'Please enter the email',
};
