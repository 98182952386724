import { useEffect, useState, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import LoaderContext from '../../shared/loader/loader-context';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';

import { dashboardApi } from './dashboardApi';

const DashboardPrograms = () => {
	let mounted = useRef();
	const ctx = useContext(LoaderContext);
	let [dashboardPrograms, setDashboardPrograms] = useState(false);

	useEffect(() => {
		mounted.current = true;
		return () => (mounted.current = false);
	});

	useEffect(() => {
		async function fetchData() {
			setDashboardPrograms('scroll');
			const response = await dashboardApi.getDashboardRecent5Programs(5);
			setDashboardPrograms(false);
			if (typeof response != 'undefined') {
				if (mounted.current) {
					if (!response?.errors) {
						setDashboardPrograms(response);
					} else {
						ctx.toasterHanlder(
							`${SHARED_CONSTANTS.ERROR}`,
							'',
							`${response.errors[0].message}`
						);
					}
				}
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${SHARED_CONSTANTS.SOMETHING_WENT_WRONG}`
				);
			}
		}
		fetchData();
	}, []);

	return (
		<div className='w-100'>
			<div className='d-flex justify-content-between align-items-center'>
				<h3 className='dashboard-title mb-0'>Programs</h3>
				<NavLink to='/program'>
					<button className='btn btn-outline-primary btn-sm ms-auto'>
						View All
					</button>
				</NavLink>
			</div>
			<div className='table-responsive custom-data-tables my-2'>
				<table className='table'>
					<thead>
						<tr>
							<th width='30%'>Program Name</th>
							<th width='30%'>Job Sites</th>
							<th width='20%'>Start Date</th>
							<th width='20%'>End Date</th>
						</tr>
					</thead>
					<tbody>
						{dashboardPrograms != 'scroll' ? (
							typeof dashboardPrograms != 'undefined' &&
							dashboardPrograms != false &&
							!!dashboardPrograms.list &&
							(!!dashboardPrograms.list.length ? (
								dashboardPrograms.list?.map((value, index) => {
									return (
										<tr key={index}>
											<td width='30%'>
												<NavLink
													to={`/program/${value?.id}`}
													className='text-decoration-none text-body'
												>
													{value?.name}
												</NavLink>
											</td>
											<td width='30%'>{value?.jobSites.join(', ')}</td>
											<td width='20%'>{value?.startDate}</td>
											<td width='20%'>{value?.endDate}</td>
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan='100%' className='text-center'>
										No Data
									</td>
								</tr>
							))
						) : (
							<tr>
								<td className='text-center' colSpan='100%'>
									<div className='spinner-border' role='status'>
										<span className='visually-hidden'>Loading...</span>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};
export default DashboardPrograms;
