import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { useForm } from 'react-hook-form';
import { usersApi } from './usersApi';
import { USER_CONSTANTS } from './usersConstant';
import LoaderContext from '../../shared/loader/loader-context';
import { Dropdown } from 'primereact/dropdown';
function AddUser(props) {
	const [isAdminFlag, setIsAdminFlag] = useState(null);
	const [roleError, setRoleError] = useState(null);

	const ctx = useContext(LoaderContext);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm(); // to set name and descriptio //react hook form
	const { users } = props;

	const roles = [
		{ name: 'Admin', is_admin: true },
		{ name: 'User', is_admin: false },
	];
	const [close, setClose] = useState(false); //close state to close modal on save & close
	//Create/update User
	const onSubmit = async (data) => {
		let postData = {...(props.users && { id: props.users.id }),
		first_name: data.users.first_name,
		last_name: data.users.last_name,
		email: data.users.email,
		password: data.users.password,
		role: data.users.role

	}
		if (isAdminFlag) {
			postData.is_admin = isAdminFlag?.is_admin;
			if (props.users) {
				ctx.loadingHandler(true);
				const response = await usersApi.updateUser(
					postData
				);
				ctx.loadingHandler(false);
				if (!response.errors) {
					ctx.toasterHanlder(
						`${SHARED_CONSTANTS.SUCCESS}`,
						'',
						`${USER_CONSTANTS.UPDATE_MSG}`
					);
				} else {
					ctx.toasterHanlder(
						`${SHARED_CONSTANTS.ERROR}`,
						'',
						`${response.errors[0].message}`
					);
				}
				// //console.log("with id", response);
			}
			else {
				ctx.loadingHandler(true);
				const response = await usersApi.registerUser(postData);
				if (!response.errors) {
					if (!response.message) {
						ctx.loadingHandler(false);

						ctx.toasterHanlder(
							`${SHARED_CONSTANTS.SUCCESS}`,
							'',
							`${USER_CONSTANTS.NEW_USER_ADDED_MSG}`
						);
						reset(); // reset input values after add/edit
						props.onFetch(); //fetch users after edit/add
						props.onCancel();
					} else {
						ctx.loadingHandler(false);

						ctx.toasterHanlder(
							`${SHARED_CONSTANTS.ERROR}`,
							'',
							`${response?.message}`
						);
						//console.log('came insdie');
					}
				} else {
					ctx.toasterHanlder(
						`${SHARED_CONSTANTS.ERROR}`,
						'',
						`${response.errors[0].message}`
					);
				}
			}
			reset();
			props.onFetch();
			if (close) {
				props.onCancel();
			}
		} else {
			setRoleError('Required');
		}
	};

	//set value when clicked on edit
	const setUsersCallBckFn = useCallback(() => {
		if (users) {
		} else {
			reset();
		}
	}, [users]);

	useEffect(() => {
		setUsersCallBckFn();
	}, [setUsersCallBckFn]);

	useEffect(()=>{
		if(!!props?.users?.role){
			setRoleError(null);
			setIsAdminFlag(roles.filter((e)=>(e.name.toLowerCase()==props?.users?.role))[0]);
		}else if(typeof props?.users ==="undefined"){
			setIsAdminFlag(null)
		}
	}, [props?.users])

	const onRoleChange = (e) => {
		setRoleError(null);
		setIsAdminFlag(e.target.value);
	};
	
	const setJobSiteHandler = useCallback(() => {
		if (users) {
			const { first_name, last_name, email, role } = users;
			setValue('users', { first_name: first_name, last_name: last_name, email: email, role: role});
		} else {
			reset();
		}
	}, [users]);

	useEffect(() => {
		setJobSiteHandler();
	}, [setJobSiteHandler]);
	return (
		<div className='card'>
			<div className='card-body'>
				<h5 className='card-title'>
					{props.users
						? SHARED_CONSTANTS.ONUPDATE_HEADER
						: SHARED_CONSTANTS.ONSAVE_HEADER}{' '}
					{USER_CONSTANTS.USER_HDR}
				</h5>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='mb-2'>
						<label>{USER_CONSTANTS.FIRST_NAME}</label>
						<input
							type='text'
							className='form-control'
							{...register('users.first_name', {
								required: true,
								pattern: SHARED_CONSTANTS.ASCII_ONLY,
							})}
						/>
						{errors?.users?.first_name?.type === 'required' && (
							<p className='error-text'>{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
						)}
						{errors?.users?.first_name?.type === 'pattern' && (
							<p className='error-text'>{SHARED_CONSTANTS.INVALID_CHARACTER}</p>
						)}
					</div>
					<div className='mb-2'>
						<label>{USER_CONSTANTS.LAST_NAME}</label>
						<input
							type='text'
							className='form-control'
							{...register('users.last_name', {
								required: true,
								pattern: SHARED_CONSTANTS.ASCII_ONLY,
							})}
						/>
						{errors?.users?.last_name?.type === 'required' && (
							<p className='error-text'>{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
						)}
						{errors?.users?.last_name?.type === 'pattern' && (
							<p className='error-text'>{SHARED_CONSTANTS.INVALID_CHARACTER}</p>
						)}
					</div>
					<div className='mb-2'>
						<label>{USER_CONSTANTS.EMAIL}</label>
						<input
							type='email'
							className='form-control'
							autoComplete="off"
							{...register('users.email', {
								required: true,
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: 'invalid email address',
								},
							})}
						/>
						{errors?.users?.email?.type === 'required' && (
							<p className='error-text'>{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
						)}
						{errors?.users?.email?.type === 'pattern' && (
							<p className='error-text'>{USER_CONSTANTS.EMAIL_VALIDATION}</p>
						)}
					</div>
					{props?.users?.id == null && 
					<div className='mb-2'>
						<label>{USER_CONSTANTS.PASSWORD}</label>
						<input
							type='password'
							className='form-control'
							autoComplete="new-password"
							{...register('users.password', { required: true })}
						/>
						{errors?.users?.name?.type === 'required' && (
							<p className='error-text'>{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
						)}
					</div>}
					<div className='mb-2'>
						<Dropdown
							value={
								(!!props?.users?.role) ?
								roles.filter((e)=>(e.name.toLowerCase()==props?.users?.role))[0] : 
								isAdminFlag
							}
							options={roles}
							onChange={onRoleChange}
							optionLabel='name'
							placeholder='Select a Role*'
							disabled={!!props?.users?.role ? true : false}
						/>
					</div>
					{roleError ? (
						<p className='error-text'>{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
					) : null}
					<div className='d-flex my-3 flex-wrap button-wrapper'>
						<button type='submit' className='btn btn-yellow mb-2'>
							{SHARED_CONSTANTS.ONSAVE}
						</button>
						<button
							type='submit'
							className='btn btn-success mb-2'
							onClick={() => setClose(true)}
						>
							{SHARED_CONSTANTS.ONSAVE_AND_CLOSE}
						</button>
						<button
							type='button'
							className='btn btn-secondary mb-2'
							onClick={props.onCancel}
						>
							{SHARED_CONSTANTS.ONCANCEL}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default AddUser;
