import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/scss/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { LoaderContextProvider } from './shared/loader/loader-context';
import { GlobalValuesContextProvider } from './shared/globalValues/globalValuesContext';
import { FileUploadNotification } from './shared/file-upload-notification/fileUploadNotificationContext';
import store from './redux/configureStore';
import {Provider} from 'react-redux'
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
		<LoaderContextProvider>
			<GlobalValuesContextProvider>
				<FileUploadNotification>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</FileUploadNotification>
			</GlobalValuesContextProvider>
		</LoaderContextProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
