import React, { useEffect, useState, useRef, useContext } from 'react';
import { Chart } from 'primereact/chart';

import { dashboardApi } from './dashboardApi';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import LoaderContext from '../../shared/loader/loader-context';

const GraphComponent = () => {
	let mounted = useRef();
	const ctx = useContext(LoaderContext);
	let [dashboardGraph, setDashboardGraph] = useState({});

	useEffect(() => {
		mounted.current = true;
		return () => (mounted.current = false);
	});

	useEffect(() => {
		async function fetchData() {
			const response = await dashboardApi.getDashboardGraph();
			if (mounted.current && typeof response != 'undefined') {
				if (!response.errors && !response.errorMessage) {
					setDashboardGraph(response);
				} else {
					ctx.toasterHanlder(
						`${SHARED_CONSTANTS.ERROR}`,
						'',
						`${response.errors[0].message}`
					);
				}
			}
		}
		fetchData();
	}, []);

	const stringMinimise = (text, limit = 9) => {
		return text.substring(0, limit) + '...';
	};

	const basicData = {
		labels: Object.keys(dashboardGraph).map(function (k) {
			return `${k}`;
		}),
		datasets: [
			{
				label: 'Percentage Completed',
				backgroundColor: [
					'#febf01',
					'#82b75e',
					'#9b9b9b',
					'#ec7c2b',
					'#4e6ea5',
				],
				data: Object.keys(dashboardGraph).map(function (k) {
					return `${dashboardGraph[k]}`;
				}),
				barThickness: 6,
			},
		],
	};

	const getLightTheme = () => {
		let horizontalOptions = {
			indexAxis: 'y',
			maintainAspectRatio: false,
			aspectRatio: 1.2,
			plugins: {
				legend: {
					display: false,
				},
			},
			scales: {
				x: {
					ticks: {
						color: '#495057',
					},
					grid: {
						color: '#ebedef',
					},
				},
				y: {
					ticks: {
						color: '#495057',
						callback: function (value, index, ticks) {
							return stringMinimise(this.getLabelForValue(value), 9);
						},
					},
					grid: {
						color: '#ebedef',
					},
				},
			},
		};

		return {
			horizontalOptions,
		};
	};

	const { horizontalOptions } = getLightTheme();
	return (
		<div>
			<Chart type='bar' data={basicData} options={horizontalOptions} />
		</div>
	);
};
export default GraphComponent;
