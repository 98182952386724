import { useContext, useState, useEffect } from 'react';
import LoaderContext from '../../shared/loader/loader-context';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import InventoryUploadComponent from './inventory-upload-component';
import { inventoryApi } from './InventoryApi';
import { INVENTORY_CONSTANTS } from './inventory-constant';
import { REPORTS_CONSTANTS } from '../reports/reports-constants';
import Modal from '../../shared/components/modal';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { MY_CALENDAR_CONSTANTS } from '../my-calendar/my-calendar-constant';
import { emailApi } from '../emails/emailsApi';
import moment from 'moment';

const InventoryUpload = () => {
	let isAdmin = localStorage.getItem('isAdmin');
	isAdmin = typeof isAdmin === 'string' ? JSON.parse(isAdmin) : isAdmin;
	const ctx = useContext(LoaderContext);
	const [inventoryList, setInventoryList] = useState([]);
	const [receiptlist, setreceiptList] = useState([]);
	const [showScheduleModal, setShowScheduleModal] = useState(false); //show/hide schedule modal
	const [selectedDate, setSelectedDate] = useState()
	const [receiptDateData, setReceiptDateData] = useState([]);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [allEmail, setAllEmail] = useState([]); 
	const [selectedEmail, setSelectedEmail] = useState(null); 


	//to get list of inventory uploaded
	const getInventoryUploadedList = async () => {
		ctx.loadingHandler(true);
		const response = await inventoryApi.getInventoryUploadedList();
		ctx.loadingHandler(false);
		if (typeof response != 'undefined') {
			if (!response.errors) {
				setInventoryList(response);
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					response.errors[0].message
				);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.SOMETHING_WENT_WRONG}`
			);
		}
	};

	//fetch all email
	const fetchAllEmail = async () => {
		const response = await emailApi.getEmailGroup();
		//console.log({ responseOfEmails: response });
		if (!response.errors) {
			setAllEmail(response);
		} else {
			setAllEmail([]);
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		}
	};

	//GSS Upload list 
	const getGSSUploadList = async() =>{
		ctx.loadingHandler(true);
		const response = await inventoryApi.GSSUploadList();
		ctx.loadingHandler(false);
		if (typeof response != 'undefined') {
			if (!response.errors) {
				setreceiptList(response);
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					response.errors[0].message
				);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.SOMETHING_WENT_WRONG}`
			);
		}
	}
	//recieptlist Data on Clicking the receipt list
	const getReceiptListDataHandler = async () =>{
		if(selectedDate){
			const response = await inventoryApi.receiptListData(selectedDate);
			if(response && !response.errors){
				setReceiptDateData(response);
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					response.errors[0].message
				);
			}
		}
	}
	const clearAll = async () => {
		ctx.loadingHandler(true);
		const response = await inventoryApi.clearAllInventoryUpload();
		ctx.loadingHandler(false);
		if (response.message) {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.SUCCESS}`,
				'',
				`${INVENTORY_CONSTANTS.CLEARED}`
			);
			getInventoryUploadedList();
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				response.errors[0].message
			);
		}
	};

	const emailHandler = (value) => {
		setButtonDisabled(false);
		setSelectedEmail(value);
	};

	const sendEmail = async () => {
		// console.log(
		// 	'send button clicked',
		// 	jobSiteToSent,
		// 	dateToSent,
		// 	selectedEmail
		// );
		setButtonDisabled(true);
		const response = await inventoryApi.sendGSSMail(selectedEmail, selectedDate);
		if (!response.errors) {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.SUCCESS}`,
				'',
				`${MY_CALENDAR_CONSTANTS.EMAIL_SENT}`
			);
			setShowScheduleModal(false);
			setButtonDisabled(true);
			setSelectedEmail(null);
		}
		// const
	};

	useEffect(() => {
		getInventoryUploadedList();
		getGSSUploadList();
		fetchAllEmail();
	}, []);

	const onModalHide = () => {
		setShowScheduleModal(false);
	};
	const uploadHanlderModalClick = (date) => {
		setShowScheduleModal(true);
		setSelectedDate(date);
	}
	useEffect(()=>{
		if(selectedDate){
			getReceiptListDataHandler();
		}
	}, [selectedDate])

	const pointerclass = inventoryList.length > 0 ? 'pointer' : 'is-disabled';

	//modal header
	const renderScheduleModalHeader = () => {
		return (
			<>
				<div className='modal-title-small'>
					{INVENTORY_CONSTANTS.RECEIPT_LIST}
				</div>
			</>
		);
	};

	//modal footer body
	const renderModalFooter = () => {
		return (
			<div className='d-flex flex-wrap'>
				<div className='d-flex flex-grow-1 me-1 mb-1'>
					<div className='me-1'>
						<label className='me-2'>{MY_CALENDAR_CONSTANTS.SELECT_EMAIL}</label>
						<Dropdown
							value={selectedEmail}
							optionLabel='email'
							optionValue='email'
							options={allEmail}
							onChange={(e) => emailHandler(e.value)}
							placeholder='Select'
						/>
					</div>
					<div>
						<Button
							disabled={buttonDisabled}
							label={SHARED_CONSTANTS.SEND}
							onClick={() => sendEmail()}
							className='btn btn-secondary me-0'
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
		{showScheduleModal && (
				<Modal
					className={'custom-modal'}
					onmodalClose={() => onModalHide()}
					modaldisplay={showScheduleModal}
					renderFooter={renderModalFooter()}
					headerLabel={renderScheduleModalHeader}
				>
					<table className='table table-striped small'>
						<thead>
							<tr>
								<th>{INVENTORY_CONSTANTS.PART_NO}</th>
								<th className='text-end'>
									{INVENTORY_CONSTANTS.QTY}
								</th>
							</tr>
						</thead>
						<tbody>
							{receiptDateData?.map((receiptData, index) => (
								<tr key={index}>
									<td>{receiptData.PartNumber}</td>
									<td className='text-end'>{receiptData.Quantity}</td>
								</tr>
							))}
							{receiptDateData.length === 0 && (
								<tr>
									<td colSpan="2">{INVENTORY_CONSTANTS.NO_RECEIPT_LIST_DATA}</td>
								</tr>
							)
							}
						</tbody>
					</table>
				</Modal>
			)}


		  	<div className='h-100 d-flex flex-column'>
				<div className='page-header d-flex justify-content-between align-items-center py-2 flex-wrap'>
					<div className='d-flex align-items-center flex-wrap'>
						<div className='page-title me-4'>
							{INVENTORY_CONSTANTS.MODULE_NAME}
						</div>
					</div>
				</div>
				<div className='d-flex overflow-auto flex-column flex-grow-1'>
					<div>
					<InventoryUploadComponent
						getInventoryUploadedList={getInventoryUploadedList}
						getGSSUploadList={getGSSUploadList}
					/>
					</div>
					<div className='d-flex flex-wrap flex-grow-1 overflow-auto'>
						<div className='flex-grow-1 me-4 h-100 overflow-auto'>
							<div className='d-flex mb-2 align-items-center'>
								<span>
									<b>{INVENTORY_CONSTANTS.PREVIOUS_INV_UPLOADS}</b>
								</span>
								{isAdmin && (
									<div
										className={`ms-auto clear-all ${pointerclass}`}
										onClick={clearAll}
									>
										{INVENTORY_CONSTANTS.CLEAR}
									</div>
								)}
							</div>
								{inventoryList?.length > 0 ? (
									<div className='table-responsive custom-data-tables'>
										<table className='table sticky-header-table'>
											<thead>
												<tr>
													<th>{INVENTORY_CONSTANTS.FILE_NAME}</th>
													<th>{INVENTORY_CONSTANTS.UPDATED_ON}</th>
												</tr>
											</thead>
											<tbody>
												{inventoryList?.map((inventory) => (
													<tr key={inventory.id}>
														<td>
															<a href=''>{inventory.name}</a>
														</td>
														<td>{inventory.create_date}</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								) : (
									<p>{REPORTS_CONSTANTS.NO_PARTS_DATA}</p>
								)}
						</div>
						<div className='flex-grow-1 h-100 overflow-auto'>
							<div className='d-flex mb-2 align-items-center'>
								<span>
									<b>{INVENTORY_CONSTANTS.PREVIOUS_GSS_UPLOADS}</b>
								</span>
								{isAdmin && (
									<div
										className={`ms-auto clear-all ${pointerclass} invisible`}
										onClick={clearAll}
									>
										{INVENTORY_CONSTANTS.CLEAR}
									</div>
								)}
							</div>
								{receiptlist?.length > 0 ? (
									<div className='table-responsive custom-data-tables'>
										<table className='table sticky-header-table'>
											<thead>
												<tr>
													<th>{INVENTORY_CONSTANTS.S_NO}</th>
													<th>{INVENTORY_CONSTANTS.DATE}</th>
													<th>{INVENTORY_CONSTANTS.GSS_STATUS}</th>
													{/* <th>{INVENTORY_CONSTANTS.UPLOADED_ON}</th> */}
													<th className='text-end'>{INVENTORY_CONSTANTS.ACTION}</th>
												</tr>
											</thead>
											<tbody>
												{receiptlist?.map((receipt, index) => (
													<tr key={index}>
														<td>{index+1}</td>
														<td>
															{moment(receipt.date).format(`${SHARED_CONSTANTS.DATE_FORMAT_US}`)}
														</td>
														<td className={`${receipt.status==='Success' ? 'green-clr': 'red-clr'}`}>
															<div>{receipt.status}</div>
														</td>
														{/* <td>
															{moment(receipt.date).format(`${SHARED_CONSTANTS.DATE_FORMAT_US}`)}
														</td> */}
														<td className='text-end'>
														<button
																className='btn btn-dark btn-sm'
																onClick={()=>uploadHanlderModalClick(receipt.date)}
																disabled={receipt.status==='failed'}
														>{INVENTORY_CONSTANTS.RECEIPT_LIST}
														</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								) : (
									<p>{REPORTS_CONSTANTS.NO_PARTS_DATA}</p>
								)}
						</div>
					</div>
					
				</div>
			</div>
		</>
	);
};

export default InventoryUpload;