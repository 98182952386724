import { api } from "../../shared/lib/api";

class emailsApi {
    async getEmailGroup(){
        return await api.get(`api/v1.0/location/grouplist/`);
    }
    async getEmailDetails(id){
        return await api.get(`api/v1.0/location/maillist/?id=${id}`);
    }

    async addEmailDetails(data){
        return await api.post(`api/v1.0/location/maillist/`,data);
    }
    async addEmailGroup(data){
        return await api.post(`api/v1.0/location/grouplist/`, data);
    }
    async editEmailGroupDetails(data,id){
        return await api.put(`api/v1.0/location/maillist/${id}/`, data);
    }
    async editEmailGroup(data,id){
        return await api.put(`api/v1.0/location/grouplist/${id}/`, data);
    }
    async deleteUser(id){
        return await api.del(`api/v1.0/location/basejoblocation/${id}/`);
    }
}

export const emailApi = new emailsApi();