import { api } from "../../shared/lib/api";

class MyCalendarApi {
    async getPrograms(){
        return await api.get(`api/v1.0/location/programs/`);
    }
    async getProgramJobSites(programId){
        return await api.get(`api/v1.0/location/programs/${programId}/`);
    }
    async getCalendarEvents(id, year, month){
        return await api.get(`api/v1.0/fileupload/calenderdata/?pk=${id}&year=${year}&month=${month}`)
    }
    async getEventDetail(id, date){
      return await api.get(`api/v1.0/fileupload/calenderdetails/?jobsiteid=${id}&date=${date}`)  
    }
    async getEventDetailWhenMailSent(id, date,email){
        return await api.get(`api/v1.0/fileupload/calenderdetailsmail/?jobsiteid=${id}&date=${date}&email=${email}`)  
      }
}
export const myCalendarApi = new MyCalendarApi();

