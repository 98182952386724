export const EMAIL_CONSTANTS = {
    MODULE_NAME: 'Users',
    DATE_LABEL: 'Date',
    HOLIDAY_LABEL:'User',
    USER_HDR: "User",
    EMAIL_HDR: "Email Group",

    EMAIL_GRP_DETAILS: "Email Group Details",

    NO_DATA_INFO: "No User found.",
    NEW_EMAIL_ADDED_MSG: "Email group added.",
    NEW_EMAIL_UPDATE_MSG: "Email group updated.",

    NEW_EMAIL_MSG: "Email added.",
    NEW_EMAIL_UPDATE: "Email updated.",


    DELETE_MSG: "Are you sure you want to delete the Email?",
    USER_NAME: "User Name",
    GRP_NAME: "Group Name*",

    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    PASSWORD: "Password",
    EMAIL: "Email*",
    IS_ADMIN: "Is Admin",
    EMAIL_VALIDATION: "Invalid Email"
}