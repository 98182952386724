export const REPORTS_CONSTANTS = {
	SHORTAGE_AMOUNT_BY_PART_NUMBER: 'SHORTAGE AMOUNT BY PART NUMBER',
	FIRST_SHORT_DATE_BY_PART_NUMBER: 'FIRST_SHORT_DATE_BY_PART_NUMBER',
	TOTAL_SHORTAGE_BY_PART_NUMBER_TO_COMPLETE_THE_PROJECT:
		'TOTAL_SHORTAGE_BY_PART_NUMBER_TO_COMPLETE_THE_PROJECT',
	VENDOR_INFORMATION: 'VENDOR INFORMATION',
	INVENTORY_AVAILABLE: 'INVENTORY AVAILABLE',
	WHAT_HAS_SHIPPED_TO_WHICH_JOB_SITE: 'WHAT HAS SHIPPED TO WHICH JOB SITE',
	TOTAL_OF_INV_SHIPPED_TO_ALL_JOB_SITES:
		'TOTAL OF INV +SHIPPED TO ALL JOB SITES',
	TOTAL_NEEDED_FOR_THE_PROJECT: 'TOTAL NEEDED FOR THE PROJECT',
	DIFFERENCE_OF_TOTAL_NEEDED_FOR_THE_PROJECT:
		'DIFFERENCE OF *TOTAL OF INV*SHIP TO ALL JOB SITES -*TOTAL NEEDED FOR THE PROJECT',
	SUMMARY_OF_LOCATION: 'Summary Of All Location',
	SUMMARY_OF_RUNOUTS: 'Summary Of Runouts',
	SELECT_PROGRAM: 'Select Program',
	NO_PARTS_DATA: 'No Records Found',
	SHIPPED_TOTAL: 'Shipped Total',
	SHIP: 'Ship',
	SHIPMENT: 'Shipment',
	INV: 'Inv',
	INVENTORY: 'Inventory',
	TOTAL: 'Total',
	BUILDABLE_UNITS: 'Buildable Units',
	QTY_PER: 'Qty Per',
	CURRENT_INV: 'Current Inv',
	SHIPPED: 'Shipped',
	TOTAL_MADE: 'Total Made',
	BUILDS: 'Builds',
	TOTAL_BUILD: 'Total Build',
	TOTAL_SITES: 'Total Sites',
	SHORTAGE_LIST: 'Shortage List',
	REMAINING_PARTS: 'Remaining Parts to Ship',
	S_NO: 'S.No',
	PARTS_NO: 'Parts No',
	DESC: 'Description',
	SHORT_AMT: 'Short Amount',
	SHORT_DT: 'Short Date',
	TOTAL_NEEDED: 'Total Needed',
	PERCENTAGE_COMPLETION: 'Percentage of Project Completion',
	TMP_SCHEDULE: 'TMP Schedule',
	NO_RECORDS_FOUND: 'No Reports found.',
	SQUARE_FOOTAGE: 'Square Footage',
	FILES_NOT_UPLOADED: 'No data found for Jobsites:',
	GSS_RECEIPT_LIST: 'GSS Receipt List',
	PERCENT: '%',
	JOBSITES_LABEL: 'Job Sites',
	REPORTS_PAGINATION_CONSTANT: 10,
	LOAD_MORE: 'Load More',
	SQUARE_FEET: 'SQUARE FEET',
	SQUARE_METER: 'SQUARE METER',
	PROGRAM_SUM: 'PROGRAM SUM',
	LH_build_min: 'LH Build Min',
	RH_build_min: 'RH Build Min',
	Packs_shipped: 'Packs Shipped',
	Shipment_pieces: 'Shipment Pieces',
	Packs_inventory: 'Packs in Inventory',
	Inventory_pieces: 'Inventory in Pieces',
	Total_pieces : 'Total Pieces'
};
