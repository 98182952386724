export const SIDEBAR_CONSTANTS = {
    COPYRIGHT_TXT:"© 2023, Steele Solutions, All rights reserved",
    MENU_LINK_1: 'Dashboard',
    MENU_LINK_2: 'Programs',
    MENU_LINK_3: 'My Calendar',
    MENU_LINK_4: 'Reports',
    LINK_4_SUBMENU_1:'Shortage List',
    LINK_4_SUBMENU_2:'Summary of All Locations',
    LINK_4_SUBMENU_3:'Summary with Runout to Show',
    LINK_4_SUBMENU_4:'Shipped Total',
    LINK_4_SUBMENU_5:'Buildable Units',
    LINK_4_SUBMENU_6:'Remaining Parts to be Shipped',
    LINK_4_SUBMENU_7:'% of Project Completion',
    // LINK_4_SUBMENU_8:'Summary for Packs/Parts',
    LINK_4_SUBMENU_8:'Need by Location',
    LINK_4_SUBMENU_9:'OHL Summary',
    LINK_4_SUBMENU_10:'Square Footage',
    LINK_4_SUBMENU_11:'Daily Receipt List',
    LINK_4_SUBMENU_12:'Ship Schedule',
    LINK_4_SUBMENU_13:'Current Manufacturing Status',
    LINK_4_SUBMENU_14:'Global Shop Supply & Demand',
    LINK_4_SUBMENU_15:'Drawing or Picture/Part',
    // LINK_4_SUBMENU_16:'Need By Location',

    MENU_LINK_5: 'Inventory Upload',
    MENU_LINK_6: 'Holidays',
    MENU_LINK_7: 'Shipping Plan',
    MENU_LINK_8: 'Job Sites',
    MENU_LINK_9: 'Users',
    MENU_LINK_10: 'Email',
}