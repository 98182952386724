import { api } from "../../shared/lib/api";

class JobSiteApi {
    async getJobSites(){
        return await api.get(`api/v1.0/location/basejoblocation/`);
    }
    async postJobSite(data){
        return await api.post(`api/v1.0/location/basejoblocation/`, data);
    }
    async updateJobSite(id, data){
        return await api.put(`api/v1.0/location/basejoblocation/${id}/`, data);
    }
    async deleteJobSite(id){
        return await api.del(`api/v1.0/location/basejoblocation/${id}/`);
    }
}

export const jobSiteApi = new JobSiteApi();