import React, { useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { FORGOTPASSSWORD_CONSTANTS } from './Forgotpassword-constant';
import { forgotpasswordApi } from './ForgotpasswordApi';
import LoaderContext from '../../shared/loader/loader-context';
import { useNavigate } from 'react-router-dom';
import LOGINLOGO from '../../assets/img/logo.png';

const ForgotPassword = () => {
	const ctx = useContext(LoaderContext);
	const navigate = useNavigate();
	let params = useParams();
	const token = params.token;
	const userid = params.userid;

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
	} = useForm({});
	const password = useRef({});
	password.current = watch('password', '');

	useEffect(() => {
		const checkToken = async () => {
			const response = await forgotpasswordApi.checkToken({
				userid: userid,
				token: token,
			});
			//console.log('checkToken = ', response);
			if (response.Error) {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.Error}`
				);
			}
		};
		checkToken();
	}, []);

	const onSubmit = async (data) => {
		const response = await forgotpasswordApi.setNewPassword({
			uidb64: userid,
			token: token,
			password: data.password,
		});
		//console.log('onSubmit = ', response);
		if (response.Status == true && !!response.message) {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.SUCCESS}`,
				'',
				`${response.message}`
			);
			navigate('/');
		} else if (!!response.errors) {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response?.errors[0]?.field}: ${response?.errors[0]?.message}`
			);
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.UNEXPECTED_ERROR}`
			);
		}
	};

	//console.log(errors);

	return (
		<div className='vh-100'>
			<div className='container py-5 h-100'>
				<div className='row d-flex justify-content-center align-items-center h-100'>
					<div className='col-12 col-md-8 col-lg-6 col-xl-5'>
						<div className='card shadow-2-strong'>
							<div className='card-body p-5 text-center'>
								<div className='mb-4'>
									<img
										src={LOGINLOGO}
										style={{ height: '60px' }}
										className='img-fluid'
										alt='login-cover'
									/>
								</div>
								<div className='card-title'>
									<h5>
										<strong>{FORGOTPASSSWORD_CONSTANTS.FORGOT_PASSWORD}</strong>
									</h5>
								</div>
								<form onSubmit={(e) => e.preventDefault()}>
									<div className='mb-2 text-start'>
										<label>{FORGOTPASSSWORD_CONSTANTS.PASSWORD}</label>
										<input
											type='password'
											className='form-control'
											{...register('password', {
												required: `${FORGOTPASSSWORD_CONSTANTS.MUST_SPECIFY_PASSWORD}`,
												minLength: {
													value: FORGOTPASSSWORD_CONSTANTS.PASSWORD_MIN_LENGTH,
													message: `${FORGOTPASSSWORD_CONSTANTS.PASSWORD_MIN_LENGTH_MSG}`,
												},
											})}
										/>
										{errors.password && (
											<p className='error-text'>{errors.password.message}</p>
										)}
									</div>
									<div className='mb-2 text-start'>
										<label>{FORGOTPASSSWORD_CONSTANTS.CONFIRM_PASSWORD}</label>
										<input
											type='password'
											className='form-control'
											{...register('password_repeat', {
												validate: (value) =>
													value === password.current ||
													`${FORGOTPASSSWORD_CONSTANTS.PASSWORDS_DONT_MATCH}`,
											})}
										/>
										{errors.password_repeat && (
											<p className='error-text'>
												{errors.password_repeat.message}
											</p>
										)}
									</div>
									<button
										className='btn btn-primary btn-lg btn-block mt-4'
										type='submit'
										onClick={handleSubmit(onSubmit)}
									>
										{SHARED_CONSTANTS.SUBMIT}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
