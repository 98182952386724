import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Dashboard from './components/dashboard/dashboard';
import Holidays from './components/holiday/holidays';
import InventoryUpload from './components/inventory-upload/inventory-upload';
import JobSites from './components/jobSites/jobSites';
import Layout from './components/layouts/layout';
import { Login } from './components/login';
import MyCalendar from './components/my-calendar/my-calendar';
import ShippingPlan from './components/shipping-plan/shipping-plan';
import NotFound from './shared/components/not-found';
import React, { lazy, useState, useEffect, useContext } from 'react';
import LoaderContext from './shared/loader/loader-context';
import { onMessageListener, fetchToken } from './firebase';
import { useFirebaseContext } from './shared/globalValues/globalValuesContext';
import UsersList from './components/user-registration/UsersList';
import { startAutoRefreshToken } from './services/token.service';
import EmailList from './components/emails/EmailList';
import EmailGroupDetail from './components/emails/emailGroupDetail';
import ForgotPassword from './components/forgot-password/ForgotPassword';
import { SHARED_CONSTANTS } from './shared/shared-constants';
const Program = lazy(() => import('./components/program/index.js'));
const Reports = lazy(() => import('./components/reports/index.js'));

function App() {
	const [firebasetoken, setFirebaseToken] = useState('');
	const ctx = useContext(LoaderContext);
	const globalState = useFirebaseContext();
	const token = localStorage.getItem('token');

	let intervalTime = 1000 * 60 * 3;

	useEffect(() => {
		startAutoRefreshToken(token, intervalTime);
	}, [token !== null]);

	useEffect(() => {
		fetchToken(setFirebaseToken);
	}, []);

	useEffect(() => {
		globalState.setNotificationToken(firebasetoken);
	}, [firebasetoken]); // eslint-disable-line react-hooks/exhaustive-deps

	onMessageListener()
		.then((payload) => {
			ctx.loadingHandler(true);
			if (
				payload.notification.title.toUpperCase() ==
				SHARED_CONSTANTS.SUCCESS.toUpperCase()
			) {
				ctx.toasterHanlder(
					SHARED_CONSTANTS.SUCCESS,
					payload.notification.title,
					payload.notification.body
				);
			} else {
				ctx.toasterHanlder(
					SHARED_CONSTANTS.ERROR,
					payload.notification.title,
					payload.notification.body
				);
			}
			ctx.loadingHandler(false);
		})
		.catch((err) => console.log('failed: ', err));

	return (
		<div className='h-100'>
			<Routes>
				<Route path='/' element={<Login />}></Route>
				<Route path='/login' element={<Login />}></Route>
				<Route
					path='/reset-password/:userid/:token'
					element={<ForgotPassword />}
				></Route>
				<Route path='*' element={<NotFound />}></Route>
				<Route element={<Layout />}>
					<Route path='/' element={<Program />}></Route>
					<Route path='/dashboard' element={<Dashboard />}></Route>
					<Route
						path='/program/*'
						element={
							<React.Suspense fallback={<>Loading...</>}>
								<Program />
							</React.Suspense>
						}
					/>
					<Route path='/my-calendar' element={<MyCalendar />}></Route>
					<Route
						path='/reports/*'
						element={
							<React.Suspense fallback={<>Loading....</>}>
								<Reports />
							</React.Suspense>
						}
					/>
					<Route path='/inventory-upload' element={<InventoryUpload />}></Route>
					<Route path='/holidays' element={<Holidays />}></Route>
					<Route path='/shipping-plan' element={<ShippingPlan />}></Route>
					<Route path='/job-sites' element={<JobSites />}></Route>
					<Route path='/users' element={<UsersList />}></Route>
					<Route path='/email' element={<EmailList />}></Route>
					<Route path='/email/:editId' element={<EmailList />}></Route>
					<Route
						path='/email/details/:id'
						element={<EmailGroupDetail />}
					></Route>
				</Route>
				<Route path='*' element={<NotFound />}></Route>
			</Routes>
		</div>
	);
}

export default App;
