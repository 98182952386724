export const INVENTORY_CONSTANTS = {
	MODULE_NAME: 'Inventory Upload',
	UPLOAD_INVENTORY: 'Upload Inventory',
	PREVIOUS_INV_UPLOADS: 'Previous Inventory Uploads',
	PREVIOUS_GSS_UPLOADS: 'Previous GSS Uploads',
	UPLOAD: 'Upload',
	GSS: 'Upload GSS ',
	CLEAR: 'Clear all',
	FILE_NAME: 'File Name',
	DATE: 'Date',
	UPDATED_ON: 'Updated On',
	SUPPORTED_FILE_TYPE:
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	CLEARED: 'All inventory data cleared.',
	FILE_TYPE_ERROR_MSG: 'File type not supported.',
	GSS_STATUS: 'Status',
	UPLOADED_ON: 'Uploaded On',
	ACTION: 'Action',
	PART_NO: 'Part Number',
	QTY: 'Quantity',
	RECEIPT_LIST: 'Receipt List',
	NO_RECEIPT_LIST_DATA: 'No Data found',
	S_NO: '#',
	FILE_UPLOAD_HEADING: 'Inventory Upload',
};
