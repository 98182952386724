import React, { useContext, useEffect, useState } from 'react';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import AddHolidayComponent from './add-holiday-component';
import { holidayApi } from './holidayApi';
import { Button } from 'primereact/button';
import Modal from '../../shared/components/modal';
import LoaderContext from '../../shared/loader/loader-context';
import { HOLIDAY_CONSTANTS } from './holiday-constants';

const Holidays = () => {
	let isAdmin = localStorage.getItem('isAdmin');
	isAdmin = typeof isAdmin === 'string' ? JSON.parse(isAdmin) : isAdmin;
	const ctx = useContext(LoaderContext);

	const [holidayModal, setHolidayModal] = useState(false); //show Add Modal
	const [showDeleteModal, setShowDeleteModal] = useState(false); // show/hide delete Modal
	const [holidays, setHolidays] = useState([]); //to set holidays to list in table
	const [selectedHoliday, setSelectedHoliday] = useState(); //holiday selected to update/delete
	const [buttonDisable, setButtonDisable] = useState(false);

	// to get all holidays to list
	const fetchHolidays = async () => {
		ctx.loadingHandler(true);
		const response = await holidayApi.getAllHolidays();
		//console.log("holiday", response);
		ctx.loadingHandler(false);
		if (typeof response != 'undefined') {
			if (!response.errors) {
				setHolidays(response); // set holidyas value from response
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.SOMETHING_WENT_WRONG}`
			);
		}
	};

	useEffect(() => {
		fetchHolidays();
	}, []);

	const holidayModalCloseHandler = () => {
		setHolidayModal(false);
		fetchHolidays(); // fetch holidays after add
	};

	// to display delet modal
	const showDeleteModalHanlder = (holiday) => {
		setShowDeleteModal(true); // to show modal
		//console.log("holiday", holiday);
		setSelectedHoliday(holiday); //set selected holiday to delete
	};

	//to delete selected holiday
	const deleteHolidayHandler = async (e) => {
		e.preventDefault();
		setButtonDisable(true);
		ctx.loadingHandler(true);
		const response = await holidayApi.deleteHoliday(selectedHoliday.id);
		ctx.loadingHandler(false);
		if (!response.errors) {
			fetchHolidays(); //fetch holidays after delete
			setShowDeleteModal(false); //close modal on delete
			setSelectedHoliday(); // after deleting set selected Holiday to null
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.ONDELETE_MSG}`
			);
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		}
		//console.log("delete holiday response", response);
		setButtonDisable(false);
	};

	//Delete Modal Footer
	const renderDeleteModalFooter = () => {
		return (
			<div>
				<Button
					label={SHARED_CONSTANTS.ONDELETE_REJECT}
					onClick={() => setShowDeleteModal(false)}
					className='btn btn-secondary'
				/>
				<Button
					label={SHARED_CONSTANTS.ONDELETE_CONFIRM}
					onClick={(e) => deleteHolidayHandler(e)}
					autoFocus
					className='btn btn-yellow'
					disabled={buttonDisable}
				/>
			</div>
		);
	};

	return (
		<>
			{/* delete Modal */}
			{showDeleteModal && (
				<Modal
					className={'custom-modal'}
					onmodalClose={() => setShowDeleteModal(false)}
					modaldisplay={showDeleteModal}
					renderFooter={renderDeleteModalFooter()}
					headerLabel='Delete Holiday'
				>
					<p>{HOLIDAY_CONSTANTS.DELETE_MSG}</p>
				</Modal>
			)}

			<div className='d-flex align-items-start flex-wrap'>
				<div className='page-header d-flex justify-content-between align-items-center py-2 flex-wrap pe-3 table-content'>
					<div className='page-title me-4'>{HOLIDAY_CONSTANTS.MODULE_NAME}</div>
					<div>
						{isAdmin && (
							<button
								className='btn btn-outline-primary btn-sm'
								onClick={() => setHolidayModal(true)}
							>
								{SHARED_CONSTANTS.ADD_BTN}
							</button>
						)}
					</div>
					<div className='w-100'>
						<div className='table-responsive mt-2 custom-data-tables'>
							<table className='table'>
								<thead>
									<tr>
										<th>#</th>
										<th>{HOLIDAY_CONSTANTS.DATE}</th>
										<th>{SHARED_CONSTANTS.DESCRIPTION_LABEL}</th>
										{isAdmin && (
											<th className='text-end action-column'>
												{SHARED_CONSTANTS.ACTION_LABEL}
											</th>
										)}
									</tr>
								</thead>
								<tbody>
									{holidays?.length === 0 && (
										<tr>
											<td colSpan='4'>{HOLIDAY_CONSTANTS.NO_DATA_INFO}</td>
										</tr>
									)}
									{holidays?.map((holiday, index) => (
										<tr key={holiday.id}>
											<td>{index + 1}</td>
											<td>{holiday.date}</td>
											<td>{holiday.description}</td>
											{isAdmin && (
												<td className='text-end'>
													<span
														title='Delete'
														className='pointer'
														onClick={() => showDeleteModalHanlder(holiday)}
													>
														<i className='bi bi-trash3 fs-6'></i>
													</span>
												</td>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className='form-wrapper py-2 px-2'>
					{holidayModal ? (
						<AddHolidayComponent
							onFetch={fetchHolidays}
							onCancel={holidayModalCloseHandler}
						/>
					) : null}
				</div>
			</div>
		</>
	);
};

export default Holidays;
