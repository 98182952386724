import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import LOGINLOGO from '../../../assets/img/logo.png';
import LOGOICON from '../../../assets/img/logo-icon.png';
import './sidebar.scss';
import { SIDEBAR_CONSTANTS } from './siderbar-constant';
const Sidebar = () => {
	let isAdmin = localStorage.getItem('isAdmin')
    isAdmin= typeof(isAdmin)==='string'?JSON.parse(isAdmin):isAdmin
	const [isActive, setActive] = useState('false');
	const reportsToggle = () => {
		setActive(!isActive);
	};
	return (
		<div className='d-flex flex-column h-100'>
			<div className='sidebar-logo d-flex my-4 px-3'>
				{' '}
				<img src={LOGINLOGO} className='img-fluid logo-normal' alt='steelesolutions' />
				<img src={LOGOICON} className='img-fluid logo-icon' alt='steelesolutions' />
			</div>
			<div className='sidebar-lists flex-grow-1 overflow-auto px-3'>
				<ul>
					<li>
						<NavLink
							className={({ isActive }) => (isActive ? 'is-active' : '')}
							to='/dashboard'
						>
							<i className='bi bi-speedometer2 fs-5 me-2'></i>
							<span>{SIDEBAR_CONSTANTS.MENU_LINK_1}</span>{' '}
						</NavLink>
					</li>
					<li>
						<NavLink
							className={({ isActive }) => (isActive ? 'is-active' : '')}
							to='/program'
						>
							<i className='bi bi-card-list fs-5 me-2'></i>
							<span> {SIDEBAR_CONSTANTS.MENU_LINK_2}</span>{' '}
						</NavLink>
					</li>
					<li>
						<NavLink
							className={({ isActive }) => (isActive ? 'is-active' : '')}
							to='/my-calendar'
						>
							<i className='bi bi-calendar3 fs-5 me-2'></i>
							<span> {SIDEBAR_CONSTANTS.MENU_LINK_3}</span>{' '}
						</NavLink>
					</li>
					<li
						className={
							isActive ? 'sidebar-dropdown' : 'sidebar-dropdown report-active'
						}
					>
						<div
							className={({ isActive }) => (isActive ? 'is-active' : '')}
							onClick={reportsToggle}
						>
							<i className='bi bi-layout-text-sidebar-reverse fs-5 me-2'></i>{' '}
							<span>{SIDEBAR_CONSTANTS.MENU_LINK_4}</span>
						</div>
						<div className='reports-list mt-2'>
							<ul>
								<li>
									<NavLink
										className={({ isActive }) => (isActive ? 'is-active' : '')}
										to='/reports/shortage-list'
									>
										<i className='bi bi-circle me-2'></i>{' '}
										{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_1}
									</NavLink>
								</li>
								<li>
									<NavLink
										className={({ isActive }) => (isActive ? 'is-active' : '')}
										to='/reports/summary-of-all-location'
									>
										<i className='bi bi-circle me-2'></i>
										{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_2}
									</NavLink>
								</li>
								<li>
									<NavLink
										className={({ isActive }) => (isActive ? 'is-active' : '')}
										to='/reports/summary-with-runout'
									>
										<i className='bi bi-circle me-2'></i>
										{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_3}
									</NavLink>
								</li>
								<li>
									<NavLink
										className={({ isActive }) => (isActive ? 'is-active' : '')}
										to='/reports/shipped-total'
									>
										<i className='bi bi-circle me-2'></i>
										{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_4}
									</NavLink>
								</li>
								<li>
									<NavLink
										className={({ isActive }) => (isActive ? 'is-active' : '')}
										to='/reports/buildable-units'
									>
										<i className='bi bi-circle me-2'></i>
										{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_5}
									</NavLink>
								</li>
								<li>
									<NavLink
										className={({ isActive }) => (isActive ? 'is-active' : '')}
										to='/reports/remaining-parts'
									>
										<i className='bi bi-circle me-2'></i>
										{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_6}
									</NavLink>
								</li>
								<li>
									<NavLink
										className={({ isActive }) => (isActive ? 'is-active' : '')}
										to='/reports/percentage-completion'
									>
										<i className='bi bi-circle me-2'></i>
										{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_7}
									</NavLink>
								</li>
								<li>
									<NavLink className={({ isActive }) => isActive ? 'is-active' : ""} to='/reports/need-by-location'>
										<i className='bi bi-circle me-2'></i>
										{/* Summary for Packs/Parts */}
										{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_8}
									</NavLink>
								</li>
								<li>
									<NavLink className={({ isActive }) => isActive ? "is-active" : ""} to="/reports/ohl-summary">
										<i className="bi bi-circle me-2"></i>
										{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_9}
									</NavLink>
								</li>
								<li><NavLink className={({ isActive }) => isActive ? "is-active" : ""} to="/reports/square-footage"><i className="bi bi-circle me-2"></i>{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_10}</NavLink></li>
								{/* <li>
									<NavLink className={({ isActive }) => isActive ? "is-active" : ""} to="/reports/receipt-list">
										<i className="bi bi-circle me-2"></i>{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_11}
									</NavLink>
								</li> */}
								{/* <li><NavLink className={({ isActive }) => isActive ? "is-active" : ""} to="/"><i className="bi bi-circle me-2"></i>{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_12}</NavLink></li> */}
								{/* <li><NavLink className={({ isActive }) => isActive ? "is-active" : ""} to="/"><i className="bi bi-circle me-2"></i>{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_13}</NavLink></li> */}
								{/* <li><NavLink className={({ isActive }) => isActive ? "is-active" : ""} to="/"><i className="bi bi-circle me-2"></i>{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_14}</NavLink></li> */}
								{/* <li><NavLink className={({ isActive }) => isActive ? "is-active" : ""} to="/"><i className="bi bi-circle me-2"></i>{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_15}</NavLink></li> */}
								{/* <li><NavLink className={({ isActive }) => isActive ? "is-active" : ""} to="/reports/need-by-location"><i className="bi bi-circle me-2"></i>{SIDEBAR_CONSTANTS.LINK_4_SUBMENU_16}</NavLink></li> */}
							</ul>
						</div>
					</li>
					<li>
						<NavLink
							className={({ isActive }) => (isActive ? 'is-active' : '')}
							to='/inventory-upload'
						>
							<i className='bi bi-upload fs-5 me-2'></i>
							<span> {SIDEBAR_CONSTANTS.MENU_LINK_5}</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							className={({ isActive }) => (isActive ? 'is-active' : '')}
							to='/holidays'
						>
							<i className='bi bi-sun-fill fs-5 me-2'></i>
							<span> {SIDEBAR_CONSTANTS.MENU_LINK_6}</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							className={({ isActive }) => (isActive ? 'is-active' : '')}
							to='/shipping-plan'
						>
							<i className='bi bi-truck fs-5 me-2'></i>{' '}
							<span>{SIDEBAR_CONSTANTS.MENU_LINK_7}</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							className={({ isActive }) => (isActive ? 'is-active' : '')}
							to='/job-sites'
						>
							<i className='bi bi-map fs-5 me-2'></i>
							<span> {SIDEBAR_CONSTANTS.MENU_LINK_8}</span>
						</NavLink>
					</li>
					{isAdmin&&<li>
						<NavLink
							className={({ isActive }) => (isActive ? 'is-active' : '')}
							to='/users'
						>
							<i className='bi bi-person fs-5 me-2'></i>
							<span> {SIDEBAR_CONSTANTS.MENU_LINK_9}</span>
						</NavLink>
					</li>}
					{isAdmin&&<li>
						<NavLink
							className={({ isActive }) => (isActive ? 'is-active' : '')}
							to='/email'
						>
							<i className='bi bi-envelope fs-5 me-2'></i>
							<span> {SIDEBAR_CONSTANTS.MENU_LINK_10}</span>
						</NavLink>
					</li>}
				</ul>
			</div>
			<div className='sidebar-footer py-2'>
				{SIDEBAR_CONSTANTS.COPYRIGHT_TXT}
			</div>
		</div>
	);
};

export default Sidebar;
