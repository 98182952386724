import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { useForm } from 'react-hook-form';
import { jobSiteApi } from './jobSiteApi';
import { JOBSITES_CONSTANTS } from './job-sites-constant';
import LoaderContext from '../../shared/loader/loader-context';

const AddNewJobsiteComponent = (props) => {
	const ctx = useContext(LoaderContext);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm(); // to set name and descriptio //react hook form
	const [close, setClose] = useState(false); //close state to close modal on save & close

	//to create/update JobSite
	const onSubmit = async (data) => {
		// //console.log("Jobsite from submit data", data.jobsite)
		let postData = {
			...(props.jobsite && { id: props.jobsite.id }), // set id if exist to update JobSite
			name: data.jobsite.name,
			description: data.jobsite.description,
		};
		if (props.jobsite) {
			// if jobsite exist update with id
			// //console.log('id', props.jobsite.id);
			// //console.log("put data", postData);
			ctx.loadingHandler(true);
			const response = await jobSiteApi.updateJobSite(
				props.jobsite.id,
				postData
			);
			ctx.loadingHandler(false);
			if (!response.errors) {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.SUCCESS}`,
					'',
					`${JOBSITES_CONSTANTS.UPDATE_MSG}`
				);
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
			// //console.log("with id", response);
		} else {
			//adding new jobsite
			// //console.log("post data", postData);
			ctx.loadingHandler(true);
			const response = await jobSiteApi.postJobSite(postData);
			ctx.loadingHandler(false);
			if (!response.errors) {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.SUCCESS}`,
					'',
					`${JOBSITES_CONSTANTS.NEW_JOBSITE_ADDED_MSG}`
				);
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
			// //console.log("Post response", response);
		}
		reset(); // reset input values after add/edit
		props.onFetch(); //fetch alljobbsites after edit/add
		if (close) {
			props.onCancel();
		}
	};

	const { jobsite } = props;

	//set name and description when clicked on edit
	const setJobSiteHandler = useCallback(() => {
		if (jobsite) {
			// //console.log("set jobsite", jobsite)
			const { name, description } = jobsite;
			// //console.log("props jobsite", jobsite);
			setValue('jobsite', { name: name, description: description });
		} else {
			reset();
		}
	}, [jobsite]);

	useEffect(() => {
		setJobSiteHandler();
	}, [setJobSiteHandler]);

	return (
		<div className='card'>
			<div className='card-body'>
				<h5 className='card-title'>
					{props.jobsite
						? SHARED_CONSTANTS.ONUPDATE_HEADER
						: SHARED_CONSTANTS.ONSAVE_HEADER}{' '}
					{JOBSITES_CONSTANTS.JOB_SITE_HDR}
				</h5>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='mb-2'>
						<label>{JOBSITES_CONSTANTS.JOBSITE_NAME_LABEL}</label>
						<input
							type='text'
							className='form-control'
							{...register('jobsite.name', {
								required: true,
								pattern: SHARED_CONSTANTS.ASCII_ONLY,
							})}
						/>
						{errors?.jobsite?.name?.type === 'required' && (
							<p className='error-text'>{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
						)}
						{errors?.jobsite?.name?.type === 'pattern' && (
							<p className='error-text'>{SHARED_CONSTANTS.INVALID_CHARACTER}</p>
						)}
					</div>
					<div className='mb-2'>
						<label>{SHARED_CONSTANTS.DESCRIPTION_LABEL}</label>
						<input
							type='text'
							className='form-control'
							{...register('jobsite.description', {
								pattern: SHARED_CONSTANTS.ASCII_ONLY,
							})}
						/>
						{errors?.jobsite?.description?.type === 'pattern' && (
							<p className='error-text'>{SHARED_CONSTANTS.INVALID_CHARACTER}</p>
						)}
					</div>
					<div className='d-flex my-3 flex-wrap button-wrapper'>
						<button type='submit' className='btn btn-yellow mb-2'>
							{SHARED_CONSTANTS.ONSAVE}
						</button>
						<button
							type='submit'
							className='btn btn-success mb-2'
							onClick={() => setClose(true)}
						>
							{SHARED_CONSTANTS.ONSAVE_AND_CLOSE}
						</button>
						<button
							type='button'
							className='btn btn-secondary mb-2'
							onClick={props.onCancel}
						>
							{SHARED_CONSTANTS.ONCANCEL}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddNewJobsiteComponent;
