import { api } from '../../shared/lib/api';

class ForgotpasswordApi {
	async checkToken(data) {
		return await api.get(
			`api/v1.0/tiffin/password-reset/${data.userid}/${data.token}`
		);
	}
	async setNewPassword(data) {
		return await api.patch(`api/v1.0/tiffin/password-reset-complete`, data);
	}
}

export const forgotpasswordApi = new ForgotpasswordApi();
