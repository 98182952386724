import { api } from "../../shared/lib/api";

class HolidayApi {
    async getAllHolidays(){
        return await api.get(`api/v1.0/location/holidays/`);
    }
    async postHoliday(data){
        return await api.post(`api/v1.0/location/holidays/`, data);
    }
    async updateHoliday(id, data){
        return await api.put(`api/v1.0/location/holidays/${id}/`, data);
    }
    async deleteHoliday(id){
        return await api.del(`api/v1.0/location/holidays/${id}/`);
    }
}

export const holidayApi = new HolidayApi();