export const USER_CONSTANTS = {
    MODULE_NAME: 'Users',
    DATE_LABEL: 'Date',
    HOLIDAY_LABEL:'User',
    USER_HDR: "User",
    NO_DATA_INFO: "No User found.",
    NEW_USER_ADDED_MSG: "User added.",
    DELETE_MSG: "Are you sure you want to delete the user?",
    USER_NAME: "User Name",
    FIRST_NAME: "First Name*",
    FIRST_NAME_LABEL: "First Name",
    UPDATE_MSG: "User Details Updated.",
    LAST_NAME_LABEL: "Last Name",
    LAST_NAME: "Last Name*",

    PASSWORD: "Password*",
    EMAIL: "Email*",
    EMAIL_LABEL: "Email",

    ROLE_LABEL: "Role",

    IS_ADMIN: "Is Admin",
    EMAIL_VALIDATION: "Invalid Email"
}