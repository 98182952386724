import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_FIREBASE_APPID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = async (setTokenFound) => {
	// vapidkey can ba found at firebase->Project settings->Cloud Messaging, click the generate button at "Web Push certificates"
	try {
		const currentToken = await getToken(messaging, {
			vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
		});
		if (currentToken) {
			setTokenFound(currentToken);
		} else {
			console.log(
				'No registration token available. Request permission to generate one.'
			);
			setTokenFound('');
		}
	} catch (err) {
		console.log('An error occurred while retrieving token. ', err);
	}
};

export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			resolve(payload);
		});
	});
