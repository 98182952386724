import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import LoaderContext from '../../shared/loader/loader-context';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { HOLIDAY_CONSTANTS } from './holiday-constants';
import { holidayApi } from './holidayApi';

const AddHolidayComponent = (props) => {
	const ctx = useContext(LoaderContext);
	const [close, setClose] = useState(false); //close modal on save & close

	const {
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm(); //react hook form to set values in input and validation

	//submit holiday
	const onSubmit = async (data) => {
		// //console.log("Post holiday", data);
		data.date = moment(data.date).format(
			`${SHARED_CONSTANTS.DATE_FORMAT_DEFAULT}`
		);
		//console.log(data);
		ctx.loadingHandler(true);
		const response = await holidayApi.postHoliday(data);
		ctx.loadingHandler(false);
		if (!response.errors) {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.SUCCESS}`,
				'',
				`${HOLIDAY_CONSTANTS.NEW_HOLIDAY_ADDED_MSG}`
			);
			props.onFetch(); // fetch holiday list after submit
			reset();
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		}
		// //console.log("Holiday post response", response);
		if (close) {
			props.onCancel();
		}
	};

	return (
		<div className='card'>
			<div className='card-body'>
				<h5 className='card-title'>
					{SHARED_CONSTANTS.ADD_BTN} {HOLIDAY_CONSTANTS.HOLIDAY_LABEL}
				</h5>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='mb-2'>
						<label>{HOLIDAY_CONSTANTS.DATE_LABEL}</label>
						<Controller
							name='date'
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Calendar
									className='w-100'
									dateFormat='mm-dd-yy'
									{...field}
									showIcon
								></Calendar>
							)}
							defaultValue=''
						/>

						{errors?.date?.type === 'required' && (
							<p className='error-text'>{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
						)}
					</div>
					<div className='mb-2'>
						<label>{HOLIDAY_CONSTANTS.DESCRIPTION_LABEL}</label>
						<Controller
							name='description'
							control={control}
							rules={{
								required: true,
								pattern: SHARED_CONSTANTS.ASCII_ONLY,
							}}
							render={({ field }) => <InputText {...field} className='w-100' />}
							defaultValue=''
						/>
						{errors?.description?.type === 'pattern' && (
							<p className='error-text'>{SHARED_CONSTANTS.INVALID_CHARACTER}</p>
						)}
						{/* {console.log(errors)} */}
					</div>
					<div className='d-flex flex-wrap my-3 button-wrapper'>
						<button className='btn btn-yellow mb-2' type='submit'>
							{SHARED_CONSTANTS.ONSAVE}
						</button>
						<button
							className='btn btn-success mb-2'
							type='submit'
							onClick={() => setClose(true)}
						>
							{SHARED_CONSTANTS.ONSAVE_AND_CLOSE}
						</button>
						<button
							className='btn btn-secondary mb-2'
							type='button'
							onClick={props.onCancel}
						>
							{SHARED_CONSTANTS.ONCANCEL}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};
export default AddHolidayComponent;
