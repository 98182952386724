import { api } from '../../shared/lib/api';

class LoginApi {
	async login(data) {
		return await api.post(`api/v1.0/tiffin/login`, data);
	}
	async checkUserRole() {
		return await api.get(`api/v1.0/user/users/`);
	}
	async sendRefreshToken(refreshToken) {
		return await api.post(`api/v1.0/tiffin/token/refresh/`, refreshToken);
	}
	async RequestPasswordResetEmail(email) {
		return await api.post(`api/v1.0/tiffin/request-reset-email/`, email);
	}
}

export const loginApi = new LoginApi();
