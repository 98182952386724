import { loginApi } from '../components/login/loginApi';
import jwt_decode from 'jwt-decode';

export const startAutoRefreshToken = async (token, intervalTime) => {
	let interval;

	const callRefreshTokenApi = async () => {
		let cookieData = document.cookie.split(';');
		let refreshToken = null;
		if (cookieData?.length > 0) {
			cookieData?.map((item) => {
				//console.log('item = ', item);
				//console.log({ item: JSON.parse(item) });
				refreshToken = JSON.parse(item);
				return;
			});
		}
		if (refreshToken !== null) {
			const response = await loginApi.sendRefreshToken(refreshToken);
			if (!response.errors) {
				//console.log({ response });
				localStorage.setItem('token', response.access);
				const access = {
					refresh: response.refresh,
				};
				document.cookie = JSON.stringify(access);
			}
		}
	};

	const refreshTokenCallingFn = () => {
		interval = null;
		callRefreshTokenApi();
	};

	const checkRefreshCondtion = (decode) => {
		const token = localStorage.getItem('token');
		if (token) {
			const expiredAt = decode;
			try {
				const expiration = new Date(decode);
				const now = new Date();
				let expirationTime = expiration.getTime();
				let currentTime = now.getTime();
				let difference = expirationTime - currentTime;
				const twoMinutes = 1000 * 60 * 2;
				if (difference < twoMinutes) {
					//console.log('token will expires inn 5 minutes');
					refreshTokenCallingFn();
				}
			} catch (error) {
				clearInterval(interval);
				interval = null;
				//console.log(error);
			}
		}
	};

	if (token !== 'undefined') {
		if (token) {
			let decode = jwt_decode(token)?.exp;
			interval = setInterval(() => {
				checkRefreshCondtion(decode);
			}, intervalTime);
		}
	}
};
