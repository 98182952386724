import { createSlice } from '@reduxjs/toolkit';

export const squareFootageSlice = createSlice({
	name: 'squareFootage',
	initialState: {
		partsFetchCompleteStatus: false,
		weekFetchCompleteStatus: false,
		totalFetchCompleteStatus: false,
		partsData: [], //metalpartsreports
		weekdata: [], //summary
		squareFootage: {},
		weekdate: {}, //datedata
	},
	reducers: {
		setPartsFetchCompleteStatus: (state, action) => {
			state.partsFetchCompleteStatus = action.payload;
		},
		setWeekFetchCompleteStatus: (state, action) => {
			state.weekFetchCompleteStatus = action.payload;
		},
		setTotalFetchCompleteStatus: (state, action) => {
			state.totalFetchCompleteStatus = action.payload;
		},
		setPartsDataReducer: (state, action) => {
			state.partsData = action.payload;
		},
		setWeekDataReducer: (state, action) => {
			state.weekdata = action.payload;
		},
		setSquareFootageReducer: (state, action) => {
			state.squareFootage = action.payload;
		},
		setWeekDateReducer: (state, action) => {
			state.weekdate = action.payload;
		},
	},
});

export const {
	setPartsFetchCompleteStatus,
	setWeekFetchCompleteStatus,
	setTotalFetchCompleteStatus,
	setPartsDataReducer,
	setWeekDataReducer,
	setSquareFootageReducer,
	setWeekDateReducer,
} = squareFootageSlice.actions;
export default squareFootageSlice.reducer;
