import React, { useRef, useState } from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

// Context for Loader and Toaster
const LoaderContext = React.createContext({
    isLoading: false, // loading is set to false
    isWeakDataLoading: false, // loading is set to false

    loadingHandler: (status)=>{}, //function to set loader status
    loadingHandlerForWeakData: (status)=>{}, //function to set loader status

    toast:null, // toast is set to null 
    toasterHanlder: (type, summary, message)=>{} // function to get toaster type, summary and message
});

// Context Provider Component
export const LoaderContextProvider = (props) => {
    const [isLoading, setIsLoading] = useState(false); //state for loader
    const [isWeakDataLoading, setIsWeakDataLoading] = useState(false); //state for loader
    const toast = useRef(null); // to set toaster type and values
    // function to set loader Stauts
    const loadingHandler = (status) => {
        setIsLoading(status)
    }
    const loadingHandlerForWeakData = (status) => {
        setIsWeakDataLoading(status)
    }
    // function to set toastr
    const toasterHanlder = (type, summary, message) => {
        toast.current.show({severity: type, summary: summary, detail:message, life: 3000});
    }
    //value passed from Loader Context Provider tp context
    const contextValue = {
        isLoading:isLoading,
        isWeakDataLoading: isWeakDataLoading,
        loadingHandlerForWeakData:loadingHandlerForWeakData,
        loadingHandler: loadingHandler,
        toast:toast,
        toasterHanlder: toasterHanlder,
    }

    return <LoaderContext.Provider value={contextValue}>
        <Toast ref={toast} />
        {props.children}
        {isLoading && (
            <div className="progress-wrap">
                <ProgressSpinner style={{left: '50%', top: '50%'}} />
            </div>
        )}
          {isWeakDataLoading && (
            <div className="progress-wrap">
                <ProgressSpinner style={{left: '50%', top: '50%'}} />
            </div>
        )}
    </LoaderContext.Provider>
}

export default LoaderContext;