export const SHARED_CONSTANTS = {
	DELETE_MSG: 'Are you sure you want to delete the record?',
	ONSAVE_HEADER: 'Add',
	ONUPDATE_HEADER: 'Update',
	ONADD_EDIT: 'Add/Edit',
	ONCANCEL: 'Cancel',
	ONCLOSE: 'Close',
	SEND: 'Send',

	ADD_EDIT_SALES: 'Add/Edit Sales',
	ONSAVE: 'Save/Update',
	ONDELETE_CONFIRM: 'Yes',
	ONDELETE_REJECT: 'No',
	ONSAVE_AND_CLOSE: 'Save & Close',
	ONADD_NEW_MSG: 'Record created.',
	ONUPDATE_MSG: 'Record updated.',
	ONDELETE_MSG: 'Deleted',
	ADD_BTN: 'Add New',
	DESCRIPTION_LABEL: 'Description',
	ACTION_LABEL: 'Action',
	ACTION_LABEL_UPDATE: 'Update',
	REQUIRED_ERR_MSG: 'Required',
	SUCCESS: 'success',
	ERROR: 'error',
	WARN: 'warn',
	FAILED_TO_FETCH: 'Failed To fetch.',
	INFO: 'info',
	DATE_FORMAT_US: 'MM-DD-YYYY',
	DATE_FORMAT_DEFAULT: 'YYYY-MM-DD',
	PROCESSING_MSG: 'Processing...',
	PROCESSING_MSG_INV: 'Daily Inventory',
	FILE_UPLOAD_MSG: 'Uploaded Successfully.',
	FILE_UPLOAD_WAIT_MSG: 'Please wait till file is uploaded.',
	FILE_UPLOAD_HEADING: 'File Upload',
	FILE_BEING_UPLOADING: 'File is being uploaded...',
	FILE_DELETE: 'File Deleting',

	CHOOSE_A_FILE: 'Please choose a file first.',
	UPLOAD_A_FILE: 'Please upload a file first.',

	UPDATE_BTN: 'Update',
	NO_FILE_SELECTED_MSG: 'Please choose file to upload',
	UPLOAD_FILE: 'Please upload a file before editing',

	DOWNLOAD_EXCEL: 'Download as Excel',
	NO_DATA: 'No Data to Save',

	ASCII_ONLY: /^[\x00-\x7F]*$/,
	INTEGER_REGEX: /(^\d{1,10}$)/g,
	DECIMAL_REGEX: /[\d]{1,99}([.]\d{1,99})?/g,
	INVALID_CHARACTER: 'Invalid Character',
	UNEXPECTED_ERROR: 'Unexpected error!!!',
	SUBMIT: 'Submit',
	CANNOT_UPLOAD_FILE:
		'Cannot upload file. Make sure the file is already being uploaded.',
	SOMETHING_WENT_WRONG: 'Something went wrong',
	FILE_UPLOAD_WARNING_MSG:
		'Please make sure the Part Number is accurate, any discrepancy can lead to computation errors or data mismatch.',
	LAST_WEEK_REACHED: 'Last week reached',
	FIRST_WEEK_REACHED: 'First week reached',
};
