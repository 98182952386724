import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

const Calendar = (props) => {
    return ( 
        <>
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView={props.intialView}
                events={props.events}
                datesSet={props.datesSet}
                headerToolbar={props.headerToolbar}
                eventClick={props.eventClick}
            />
        </>
     );
}
 
export default Calendar;