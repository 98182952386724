import React, { useContext, useState } from 'react';
import { programApi } from '../../components/program/programApi';

export const globalValues = React.createContext();

export function useFirebaseContext() {
	return useContext(globalValues);
}

export function GlobalValuesContextProvider({ children }) {
	const [firebaseNotificationToken, setFirebaseNotificationToken] =
		useState('');
	const [programDetail, setProgramDetail] = useState();

	const fetchProgramDetails = async (programId) => {
		const response = await programApi.getProgramDetail(programId);
		setProgramDetail({ ...response });
	};

	return (
		<globalValues.Provider
			value={{
				notificationToken: firebaseNotificationToken,
				setNotificationToken: setFirebaseNotificationToken,
				programDetail: programDetail,
				fetchProgramDetails: fetchProgramDetails,
				setProgramDetail: setProgramDetail,
			}}
		>
			{children}
		</globalValues.Provider>
	);
}
