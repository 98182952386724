import { configureStore, compose, applyMiddleware } from '@reduxjs/toolkit';
import programReducer from '../components/program/programReducer';
import reports__summary_of_all_location_reducer from '../components/reports/summary-of-all-location/summary-of-all-location-reducer';
import reports__summary_of_runouts_reducer from '../components/reports/summary-with-runout/summary-of-runouts-reducer';
import reports__square_footage_reducer from '../components/reports/square-footage/square-footage-reducer';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default configureStore(
	{
		reducer: {
			program: programReducer,
			reports__summary_of_all_location:
				reports__summary_of_all_location_reducer,
			reports__summary_of_runouts: reports__summary_of_runouts_reducer,
			reports__square_footage: reports__square_footage_reducer,
		},
	},
	composeEnhancers(applyMiddleware())
);
