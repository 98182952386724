import React, { useState, useEffect, useContext } from 'react';
import { globalValues } from '../globalValues/globalValuesContext';
import { API_ROOT_WS } from '../lib/apiConfig';
import LoaderContext from '../loader/loader-context';
import { SHARED_CONSTANTS } from '../shared-constants';

import './FileUploadNotification.scss';

export const fileUploadNotificationContext = React.createContext();

export function FileUploadNotification(props) {
	const [list, setList] = useState([]);
	const [uniqueID, setUniqueID] = useState(1);
	const [percentage, setPercentage] = useState(false);
	const [deleteToastId, setDeleteToastId] = useState(false);
	const [removeFileUploadNotificationId, setRemoveFileUploadNotificationId] =
		useState(false);
	const [celeryError, setCeleryError] = useState(false);

	const ctx = useContext(LoaderContext);
	const { fetchProgramDetails } = useContext(globalValues);

	// console.log('list in context = ', list);

	useEffect(() => {
		if (deleteToastId) {
			const listItemIndex = list.findIndex((e) => e.id === deleteToastId);
			if (!!list[listItemIndex].socket) {
				list[listItemIndex].socket.close();
			}
			list.splice(listItemIndex, 1);
			setList([...list]);
		}
	}, [deleteToastId]);

	useEffect(() => {
		if (celeryError) {
			const listItemIndex = list.findIndex((e) => e.id === celeryError.id);
			console.log('celeryError = ', list[listItemIndex]);
			if (!!list[listItemIndex]) {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					`Error while importing "${list[listItemIndex].title}"`,
					`${celeryError.Error}`
				);
				if (!!list[listItemIndex].socket) {
					list[listItemIndex].socket.close();
				}
				list.splice(listItemIndex, 1);
				setList([...list]);
			}
		}
	}, [celeryError]);

	useEffect(() => {
		if (removeFileUploadNotificationId) {
			//console.log('removeFileUploadNotification called', list);
			const listItemIndex = list.findIndex(
				(e) => e.id === removeFileUploadNotificationId
			);
			if (listItemIndex >= 0) {
				list.splice(listItemIndex, 1);
				setList([...list]);
			}
		}
	}, [removeFileUploadNotificationId]);

	useEffect(() => {
		if (percentage) {
			let temp = [];
			const listItemIndex = list.findIndex((e) => e.id === percentage.id);
			if (listItemIndex >= 0) {
				temp = [...list];
				temp[listItemIndex].celeryId = percentage.celeryToken;
				temp[listItemIndex].percentage = Math.floor(percentage.percentage);
				temp[listItemIndex].Stage = percentage.Stage;
				temp[listItemIndex].socket = percentage.socket;
				//console.log('updatePercentage inside = ', temp, list);
				setList([...temp]);
			}
		}
	}, [percentage]);

	const addFileUploadNotification = (newToast) => {
		//console.log('addFileUploadNotification called', list);
		newToast.id = uniqueID;
		setUniqueID((prev) => prev + 1);
		setList((prev) => {
			if (!!newToast) {
				//console.log('addFileUploadNotification IF');
				newToast.percentage = 0;
				newToast.Stage = false;
				return [...prev, newToast];
			} else {
				//console.log('addFileUploadNotification ELSE');
				return prev;
			}
		});
		return uniqueID;
	};

	const TrackCeleryTask = async (id, celery_task_id, callback = Function()) => {
		// console.log('TrackCeleryTask = ', list);
		if (!!celery_task_id) {
			const socket = new WebSocket(
				API_ROOT_WS + `ws/v1.0/celery-progress/${celery_task_id}/`
			);
			socket.onmessage = (event) => {
				const parsedEvent = JSON.parse(event.data);
				// console.log('socket success= ', parsedEvent);
				if (!parsedEvent.Completed) {
					if (
						!!parsedEvent.numerator &&
						!!parsedEvent.denominator &&
						!!parsedEvent.Stage
					) {
						let percentageLoc =
							(parsedEvent.numerator / parsedEvent.denominator) * 100;
						//console.log('updatePercentage = ', list);
						setPercentage({
							id: id,
							percentage: Math.floor(percentageLoc),
							Stage: parsedEvent.Stage,
							celeryToken: celery_task_id,
							socket: socket,
						});
					}
				} else {
					if (!!parsedEvent.Error) {
						setCeleryError({ id: id, Error: parsedEvent.Error });
					} else {
						socket.close();
						setRemoveFileUploadNotificationId(id);
						!!parsedEvent?.programid &&
							fetchProgramDetails(parsedEvent.programid);
						callback();
					}
				}
			};
			socket.onerror = (err) => {
				// console.log('socket onerror = ', err);
				setRemoveFileUploadNotificationId(id);
			};
			socket.onclose = (event) => {
				// console.log('socket onclose = ', event);
				setRemoveFileUploadNotificationId(id);
			};
			socket.onopen = (event) => {
				// console.log('socket onopen = ', event);
			};
		} else {
			setCeleryError({ id: id, Error: SHARED_CONSTANTS.UNEXPECTED_ERROR });
		}
	};

	const clearAllFileUploadNotification = () => {
		list.forEach((element) => {
			if (!!element.socket) {
				element.socket.close();
			}
		});
		setList([]);
	};

	const stringMinimise = (text, limit = 9) => {
		return text.substring(0, limit) + '...';
	};

	return (
		<fileUploadNotificationContext.Provider
			value={{
				addFileUploadNotification,
				setRemoveFileUploadNotificationId,
				TrackCeleryTask,
				clearAllFileUploadNotification,
			}}
		>
			<div className={`notification-container bottom-right`}>
				{!!list.length &&
					list.map((toast, i) => (
						<div
							key={i}
							className={`notification toasts bottom-right`}
							style={{ backgroundColor: toast.backgroundColor }}
						>
							<button onClick={() => setDeleteToastId(toast.id)}>X</button>
							<div className='notification-image'>
								<div className='spinner-border' role='status'>
									<span className='visually-hidden'>Loading...</span>
								</div>
							</div>
							<div>
								<p className='notification-title'>
									{stringMinimise(toast.title, 27)}
								</p>
								<p className='notification-message'>
									{toast.Stage
										? `${toast.Stage}: ${toast.percentage}% Completed`
										: 'Processing...'}
								</p>
							</div>
						</div>
					))}
			</div>
			{props.children}
		</fileUploadNotificationContext.Provider>
	);
}
