import { api } from '../../shared/lib/api';

class ProgramApi {
	async getProgramDetail(programId) {
		return await api.get(`api/v1.0/location/programs/${programId}/`);
	}
	async getPrograms() {
		return await api.get(`api/v1.0/location/programs/`);
	}
	async postProgram(data) {
		return await api.post(`api/v1.0/location/programs/`, data);
	}
	async updateProgram(id, data) {
		return await api.put(`api/v1.0/location/programs/${id}/`, data);
	}
	async deleteProgram(id, data) {
		return await api.patch(`api/v1.0/location/programs/${id}/`, data);
	}
	async getProgramsOnFilter(data) {
		var url = `api/v1.0/location/programs/?`;
		if (data !== 'all') {
			url += `search=${data}`;
		}
		return await api.get(url);
	}
	async getPartsData(id, partNumber) {
		var url = `api/v1.0/fileupload/metalparts/?pk=${id}&`;
		if (partNumber) {
			url += `part_number=${partNumber}`;
		}
		return await api.get(url);
	}
	async getWeekData(weekNo, id, partsData, year) {
		var url = `api/v1.0/fileupload/metalpartsschedule/?week_number=${weekNo}&pk=${id}&`;
		if (partsData) {
			url += `metal_parts=${partsData}&`;
		}
		if (year) {
			url += `&year=${year}`;
		}
		return await api.get(url);
	}
	async jobSiteFileUpload(id, firebaseToken, data, string, programid) {
		return await api.postFile(
			`api/v1.0/fileupload/fileupload/?pk=${id}&firebasetoken=${firebaseToken}&status=${string}&programid=${programid}`,
			data
		);
	}

	async deleteUploadedFile(JobSiteId) {
		return await api.post(`api/v1.0/fileupload/filedelete/?pk=${JobSiteId}`);
	}

	async getWeekCount(id, date, partNumber) {
		var url = `api/v1.0/fileupload/weeknumcount/?pk=${id}&`;
		if (date) {
			url += `date=${date}&`;
		}
		if (partNumber) {
			url += `part_number=${partNumber}&`;
		}

		return await api.get(url);
	}
	async editSchedule(anyScheduleid, data) {
		//console.log({ data, anyScheduleid });
		return await api.put(
			`api/v1.0/fileupload/scheduleedit/${anyScheduleid}/`,
			data
		);
	}
	async getWeekDates(weekNo, id, year) {
		return await api.get(
			`api/v1.0/fileupload/datedata/?week_number=${weekNo}&pk=${id}&year=${year}`
		);
	}
	async getBuildableUnits(weekNo, id, year) {
		// `api/v1.0/fileupload/unitschedule/?week_number=${weekNo}&pk=${id}` //old api

		return await api.get(
			`api/v1.0/fileupload/unitschedulemod/?week_number=${weekNo}&pk=${id}&year=${year}`
		);
	}
	async getLastUploadedFile(programid, jobsiteid) {
		return await api.get(
			`api/v1.0/fileupload/lastuploadedfile/?programid=${programid}&jobsiteid=${jobsiteid}`
		);
	}
	async getJobsiteStartAndEndDate(jobsiteId) {
		return await api.get(`api/v1.0/fileupload/minmaxdate/?pk=${jobsiteId}`);
	}
	async getFilteredParts(jobsiteId, partNo) {
		return await api.get(
			`api/v1.0/fileupload/metalpartssearchmod/?pk=${jobsiteId}&part_number=${partNo}`
		);
	}
	async getFilteredSchedules(weekNo, jobsiteId, partNo, year) {
		return await api.get(
			`api/v1.0/fileupload/metalpartssearchmod1/?week_number=${weekNo}&pk=${jobsiteId}&part_number=${partNo}&year=${year}`
		);
	}
	async getSalesOrder(weekNo, jobsiteId, year) {
		return await api.get(`api/v1.0/fileupload/salesorder/?week_number=${weekNo}&pk=${jobsiteId}&year=${year}`)
	}
	async updateSalesOrders(weekNo, jobsiteId, year, data) {
		// console.log("data", data);
		return await api.post(`api/v1.0/fileupload/salesorder/?week_number=${weekNo}&pk=${jobsiteId}&year=${year}`, data)
	}
}
export const programApi = new ProgramApi();
