import { api } from '../../shared/lib/api';

class InventoryApi {
	async inventoryFileUpload(data, firebaseToken) {
		return await api.postFile(
			`api/v1.0/fileupload/dailyinventory/?firebasetoken=${firebaseToken}`,
			data
		);
	}
	async getInventoryUploadedList() {
		return await api.get(`api/v1.0/fileupload/dailyinventory/`);
	}
	async clearAllInventoryUpload(){
		return await api.post(`api/v1.0/fileupload/clearall/`, null)
	}
	async GSSUpload(){
		return await api.post(`api/v1.0/reports/receiptlist/`, null)
	}
	async GSSUploadList(){
		return await api.get(`api/v1.0/reports/receiptlist/`)
	}
	async receiptListData(date){
		return await api.get(`api/v1.0/reports/receiptshortlist/?date=${date}`);
	}
	async sendGSSMail(mail, date){
		return await api.get(`api/v1.0/reports/receiptlistemail/?email=${mail}&date=${date}`);
	}
}
export const inventoryApi = new InventoryApi();
