import React, { useCallback, useContext, useEffect, useState } from "react";
import { SHARED_CONSTANTS } from "../../shared/shared-constants";
import { useForm } from "react-hook-form";
import { emailApi } from "./emailsApi";
import { EMAIL_CONSTANTS } from "./emailConstant";
import LoaderContext from "../../shared/loader/loader-context";
import { Dropdown } from 'primereact/dropdown';
import { useParams,useNavigate } from "react-router-dom";

function AddEmailDetail(props) {
    const navigate = useNavigate()
    const {id} = useParams()
    const { email } = props;

    const [isAdminFlag,setIsAdminFlag] = useState(null)
    const ctx = useContext(LoaderContext);
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue
    } = useForm(); // to set name and descriptio //react hook form

    const onSubmit = async (data) => {
        let postData = data?.email;
        //console.log({postData})
        // if(isAdminFlag){
        //     postData.is_admin = isAdminFlag?.is_admin
        // }
        // ctx.loadingHandler(true);
        if(email){
            //console.log('inside edit')
            // postData.group=id
            const response = await emailApi.editEmailGroupDetails(postData,email?.id);
            
            ctx.loadingHandler(false);
            
            props.onCancel();
            if (!response.errors) {
              ctx.toasterHanlder(
                `${SHARED_CONSTANTS.SUCCESS}`,
                "",
                `${EMAIL_CONSTANTS.NEW_EMAIL_UPDATE}`
              );
              props.onFetch(); //fetch users after edit/add
              props.onCancel();
            //   navigate('/email')
            } else {
              ctx.toasterHanlder(
                `${SHARED_CONSTANTS.ERROR}`,
                "",
                `${response.errors[0].message}`
              );
            }
        } else {
            //console.log('insdide post')
            if (postData) { 
                postData.group=id
              const response = await emailApi.addEmailDetails(postData);
              ctx.loadingHandler(false);
              if (!response.errors) {
                ctx.toasterHanlder(
                  `${SHARED_CONSTANTS.SUCCESS}`,
                  "",
                  `${EMAIL_CONSTANTS.NEW_EMAIL_MSG}`
                );
                reset(); // reset input values after add/edit
                props.onCancel();
                props.onFetch(); //fetch users after edit/add
              } else {
                ctx.toasterHanlder(
                  `${SHARED_CONSTANTS.ERROR}`,
                  "",
                  `${response.errors[0].message}`
                );
              }
            }
        }
        // reset()
      };

    const setEmailCallBckFn = useCallback(() => {
        //console.log({email})
            if (email) {
                //console.log('first here caleleeddd')
                const {email:emailGrpEmail, group_name} = email;
                setValue("email", {email:emailGrpEmail});
            } else {
                //console.log('****')
              reset();
            }

    }, [email]);

    useEffect(() => {
        setEmailCallBckFn();
      }, [setEmailCallBckFn]);
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">
          {props.users
            ? SHARED_CONSTANTS.ONUPDATE_HEADER
            : SHARED_CONSTANTS.ONSAVE_HEADER}{" "}
          {EMAIL_CONSTANTS.EMAIL_GRP_DETAILS}
        </h5>
        <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className="mb-2">
            <label>{EMAIL_CONSTANTS.GRP_NAME}</label>
            <input
              type="text"
              className="form-control"
              {...register("email.group_name", { required: true })}
            />
            {errors?.email?.group_name?.type === "required" && (
              <p className="error-text">{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
            )}
          </div> */}
        <div className="mb-2">
            <label>{EMAIL_CONSTANTS.EMAIL}</label>
            <input
              type="email"
              className="form-control"
              {...register("email.email", { required: true,pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              } })}
            />
            {errors?.email?.email?.type === "required" && (
              <p className="error-text">{SHARED_CONSTANTS.REQUIRED_ERR_MSG}</p>
            )}
            	{errors?.email?.email?.type === 'pattern' && (
								<p className='error-text'>{EMAIL_CONSTANTS.EMAIL_VALIDATION}</p>
							)}
          </div>
          <div className="d-flex my-3 flex-wrap button-wrapper">
            <button type="submit" className="btn btn-yellow mb-2">
              {SHARED_CONSTANTS.ONSAVE}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddEmailDetail