import React, { useEffect, useState, useContext } from 'react';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import Modal from '../../shared/components/modal';
import { Dropdown } from 'primereact/dropdown';
import Calendar from '../../shared/components/calendar';
import { Button } from 'primereact/button';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { MY_CALENDAR_CONSTANTS } from './my-calendar-constant';
import { myCalendarApi } from './myCalendarApi';
// import moment from 'moment';
import LoaderContext from '../../shared/loader/loader-context';
import { emailApi } from '../emails/emailsApi';
import moment from 'moment-timezone';

const MyCalendar = () => {
	const ctx = useContext(LoaderContext);
	const [calendarEvents, setCalendarEvents] = useState([]); //set calendar events
	const [programs, setPrograms] = useState([]); //set list of programs for dropdown
	const [program, setProgram] = useState(); //set selected program
	const [jobSites, setJobSites] = useState([]); // set list of jobsites for dropdown
	const [jobsite, setJobSite] = useState(); //set selected jobsite
	const [showScheduleModal, setShowScheduleModal] = useState(false); //show/hide schedule modal
	const [eventDetail, setEventDetail] = useState([]); //set selected event detail
	const [month, setMonth] = useState(moment().month() + 1); //set selected month
	const [year, setYear] = useState(moment().year()); //set selected year
	const [eventTitle, setEventTitle] = useState(''); // set event title
	const [salesNumber, setSalesNumber] = useState(''); // set event Sales number
	const [programObject, setProgramObject] = useState({}); // set event title
	const [truckCountString, setTruckCountString] = useState(''); // set event title
	const [currentDate, setCurrentDate] = useState(''); // set event title
	const [allEmail, setAllEmail] = useState([]); // set event title
	const [selectedEmail, setSelectedEmail] = useState(null); // set event title
	const [dateToSent, setDateToSent] = useState(null); // set event title
	const [jobSiteToSent, setJobSiteToSent] = useState(null); // set event title
	const [buttonDisabled, setButtonDisabled] = useState(true); // set event title

	//header toolbar option for calendar
	const headerToolbar = {
		left: 'prev,next today',
		center: 'title',
		right: 'dayGridMonth, dayGridWeek, dayGridDay',
	};

	const fetchAllEmail = async () => {
		const response = await emailApi.getEmailGroup();
		//console.log({ responseOfEmails: response });
		if (!response.errors) {
			setAllEmail(response);
		} else {
			setAllEmail([]);
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		}
	};

	//fetch  all programs to display in dropdown
	const fetchAllPrograms = async () => {
		ctx.loadingHandler(true);
		const response = await myCalendarApi.getPrograms();
		ctx.loadingHandler(false);
		//console.log('response[0].id= ', response, response.length);
		if (typeof response != 'undefined') {
			if (!response.errors) {
				if (!!response.length) {
					setPrograms(response);
					if (!program) {
						setProgram(response[0].id);
						setProgramObject(response[0]);
					}
					// fetchAllJobSites(response[0].id);
					// console.log('response all program', response);
				}
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.SOMETHING_WENT_WRONG}`
			);
		}
		// //console.log("Programs response", response[0]?.id)
	};

	//fetch jobsites related to program
	const fetchAllJobSites = async (programId) => {
		ctx.loadingHandler(true);
		// console.log("programid",programId);
		// console.log("program",program);
		const response = await myCalendarApi.getProgramJobSites(programId);
		ctx.loadingHandler(false);
		if (!response.errors) {
			setJobSites(response.JobsitePrograms);
			if (!jobsite) {
				setJobSite(response.JobsitePrograms[0].id);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response?.errors[0].message}`
			);
		}
	};

	//fetch shipping info of selected program
	const fetchShippinInfo = async () => {
		let eventsArray = [];
		// //console.log("Current month and date", month, "year", year);
		// //console.log("Program shipping info program id", program);
		ctx.loadingHandler(true);
		const response = await myCalendarApi.getCalendarEvents(
			program,
			year,
			month
		);
		ctx.loadingHandler(false);
		// //console.log('response events calendar', response);
		// //console.log("response.length", response.length)
		if (!response.errors && response?.length > 0) {
			response?.map((programEvent) => {
				if (programEvent.truck_sum !== 0) {
					let event = {
						id: programEvent.job_id,
						title: `${programEvent.job_sites} (${programEvent.truck_sum} Trucks)`,
						date: moment(programEvent.custom_date).tz("UTC").format(
							`${SHARED_CONSTANTS.DATE_FORMAT_DEFAULT}`
						),
						defaultDate: moment(programEvent.date).tz("UTC").format(
							`${SHARED_CONSTANTS.DATE_FORMAT_DEFAULT}`
						),
						SalesNumber: programEvent.sales_number
					};
					eventsArray.push(event);
				}
				// //console.log("date converted from api", moment(new Date(programEvent.date)).format('YYYY-MM-DD'));
			});
			//console.log('Events Array', eventsArray);
			setCalendarEvents(eventsArray);
			// console.log("eventsArray", eventsArray)
		} else if (response?.errors) {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		} else if (response.length === 0) {
			setCalendarEvents();
		}
	};

	//set date on change (prev/next)
	const dateSetHandler = (info) => {
		//console.log({ info });
		// //console.log("datesetHandler start", info.view.currentStart);
		var date = moment(info.view.currentStart);
		setMonth(date.month() + 1);
		setYear(date.year());
		// //console.log("month", date.month(), "Year", date.year())
		// //console.log("datesetHandler end", info.view.currentEnd);
	};

	// use effect onchange of program, month, year
	useEffect(() => {
		if (program) {
			// console.log("use effect program", program);
			fetchShippinInfo();
			fetchAllJobSites(program);
		}
	}, [program, month, year]);

	//fetch all programs
	useEffect(() => {
		fetchAllPrograms();
	}, []);
	useEffect(() => {
		fetchAllEmail();
	}, [program]);

	//on event click show popup
	const handleEventClick = async (event) => {
		//console.log({ program, programObject });
		// console.log('event handle event click', event);
		// console.log('event defaulat date', event.event._def.extendedProps.defaultDate);
		let currentDate = moment(event.event.start).tz("UTC").format(
			`${SHARED_CONSTANTS.DATE_FORMAT_US}`
		);
		setCurrentDate(currentDate);
		setSalesNumber(event?.event?._def?.extendedProps?.SalesNumber)
		// setTruckCountString(event.jsEvent?.target?.innerText);
		setEventTitle(event.event.title.split(' ')[0]);
		//console.log({ eventsss: event.event.title.split('(') });
		//console.log({ eventsss: event.event.title.split('(') });
		// let truckString = event.event.title.split('(')[1];
		// truckString = truckString.replace(')', '');
		// console.log('truck', event.event.title.split(' ')[1].replace('(',''))
		// setTruckCountString(truckString);
		

		var jobsiteId = event.event.id;
		// //console.log("event date", event.event.start);
		// //console.log("mment format", moment(event.event.start).format('YYYY-MM-DD'))
		var date =
			moment(event.event._def.extendedProps.defaultDate).format(
				`${SHARED_CONSTANTS.DATE_FORMAT_DEFAULT}`
			) + 'T00:00:00.000Z';
		setDateToSent(date);
		setJobSiteToSent(jobsiteId);
		const response = await myCalendarApi.getEventDetail(jobsiteId, date);
		setEventDetail(response);
		let _totTrucks = response.map(item => item.truck).reduce((prev, curr) => prev + curr, 0);
		// console.log("total trucks",_totTrucks);
		setTruckCountString(Math.ceil(_totTrucks));
		// //console.log("Event Detail response", response);
		setShowScheduleModal(true);
		// //console.log("el", el);
	};

	//modal hide
	const onModalHide = () => {
		setShowScheduleModal(false);
	};

	//fetch all programs when a program is selected
	const getScheduleByProgramHandler = (val) => {
		programs.find((item) => {
			if (item.id === val) {
				setProgramObject(item);
			}
		});
		setProgram(val);
		fetchAllJobSites(val);
		// fetchAllPrograms();
	};

	const emailHandler = (value) => {
		setButtonDisabled(false);
		setSelectedEmail(value);
	};

	const sendEmail = async () => {
		// console.log(
		// 	'send button clicked',
		// 	jobSiteToSent,
		// 	dateToSent,
		// 	selectedEmail
		// );
		setButtonDisabled(true);
		const response = await myCalendarApi.getEventDetailWhenMailSent(
			jobSiteToSent,
			dateToSent,
			selectedEmail
		);
		if (response && !response.errors) {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.SUCCESS}`,
				'',
				`${MY_CALENDAR_CONSTANTS.EMAIL_SENT}`
			);
			setShowScheduleModal(false);
			setButtonDisabled(true);
			setSelectedEmail(null);
		}
		// const
	};

	const generateExcelData = (eventDetail) => {
		let data = [
			[
				`${programObject ? programObject.name : ''} (${
					currentDate ? currentDate : ''
				})`,
			],
			[`${MY_CALENDAR_CONSTANTS.JOBSITE} ${eventTitle ? eventTitle : ''}`],
			[`${MY_CALENDAR_CONSTANTS.CUSTOM_SHIP_DATE}`, `${currentDate ? currentDate : ''}`],
			[`${MY_CALENDAR_CONSTANTS.SHIP_DATE}`, `${dateToSent ? moment(dateToSent).tz("UTC").format(`${SHARED_CONSTANTS.DATE_FORMAT_US}`) : ''}`],
			[`${MY_CALENDAR_CONSTANTS.SALESORDER}`, `${salesNumber ? salesNumber : 0}`],
			['Part Number', 'No. of Parts', 'Trucks Needed'],
		];

		// // Convert the data to Array of Array format
		if (!!eventDetail.length) {
			eventDetail.forEach((entry, row) => {
				data.push([entry.part_name, entry.pieces, entry.truck]);
			});
		}

		data.push([`TOTAL: ${truckCountString ? truckCountString : ''} ${MY_CALENDAR_CONSTANTS.TRUCKS}`]);

		// Create merge data based on the Array of Array
		let merge = [];
		const column = 2; //starts from zero
		data.forEach((element, row) => {
			if (element.length == 1) {
				merge.push({ s: { r: row, c: 0 }, e: { r: row, c: column } }); //s=start r=row c=column e=end
			}
		});
		/* generate worksheet */
		var ws = XLSX.utils.aoa_to_sheet(data);
		/* add merges */
		if (!ws['!merges']) ws['!merges'] = [];
		ws['!merges'].push(...merge);
		/* generate workbook */
		var wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
		/* generate file and download */
		const wbout = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
		FileSaver.saveAs(
			new Blob([wbout], { type: 'application/octet-stream' }),
			`${programObject ? programObject.name : ''} ${
				currentDate ? currentDate : ''
			} ${MY_CALENDAR_CONSTANTS.JOBSITE} ${eventTitle ? eventTitle : ''}.xlsx`
		);
	};

	//fetch all programs when a jobsite is selected
	// const getScheduleByJobSiteHandler = (val) => {
	// 	setJobSite(val);
	// 	fetchAllPrograms();
	// };
	//schedule modal header body
	const renderScheduleModalHeader = () => {
		return (
			<>
				<div className='modal-title-small'>
					<div>
						{/* {MY_CALENDAR_CONSTANTS.PROGRAM_NAME}{' '} */}
						<span>{programObject ? programObject.name : ''}</span> 
					</div>
					<div>{MY_CALENDAR_CONSTANTS.CUSTOM_SHIP_DATE}: {currentDate ? currentDate : ''}</div>
					<div>{MY_CALENDAR_CONSTANTS.SHIP_DATE}: {dateToSent ? moment(dateToSent).tz("UTC").format(`${SHARED_CONSTANTS.DATE_FORMAT_US}`) : ''}</div>
					<div>
						{MY_CALENDAR_CONSTANTS.JOBSITE}{' '}
						<span>{eventTitle ? eventTitle : ''}</span>
					</div>
					<div>
						{MY_CALENDAR_CONSTANTS.SALESORDER}{' '}
						<span>{salesNumber ? salesNumber : '0'}</span>
					</div>
				</div>
			</>
		);
	};

	//modal footer body
	const renderModalFooter = () => {
		return (
			<div className='d-flex flex-wrap'>
				<div className='d-flex flex-grow-1 me-1 mb-1'>
					<div className='me-1'>
						<label className='me-2'>{MY_CALENDAR_CONSTANTS.SELECT_EMAIL}</label>
						<Dropdown
							value={selectedEmail}
							optionLabel='email'
							optionValue='email'
							options={allEmail}
							onChange={(e) => emailHandler(e.value)}
							placeholder='Select'
						/>
					</div>
					<div>
						{/* <Button
						label={SHARED_CONSTANTS.DOWNLOAD_EXCEL}
						onClick={() => generateExcelData(eventDetail)}
						className='btn btn-primary me-1'
					/> */}
						<Button
							disabled={buttonDisabled}
							label={SHARED_CONSTANTS.SEND}
							onClick={() => sendEmail()}
							className='btn btn-secondary me-0'
						/>
					</div>
				</div>
				<div className=' mb-1'>
					<Button
						label={SHARED_CONSTANTS.DOWNLOAD_EXCEL}
						onClick={() => generateExcelData(eventDetail)}
						className='btn btn-primary me-1'
					/>
				</div>
			</div>
		);
	};
	return (
		<>
			<div className='my-calendar-wrapper'>
				<div className='page-header d-flex align-items-center'>
					<div className='page-title me-4'>
						{MY_CALENDAR_CONSTANTS.MODULE_NAME}
					</div>
					<div className='d-flex ms-auto'>
						<div className='d-flex align-items-center me-3'>
							<label className='me-2'>
								{MY_CALENDAR_CONSTANTS.SELECT_PROGRAM}
							</label>
							<Dropdown
								value={program}
								optionLabel='name'
								optionValue='id'
								options={programs}
								onChange={(e) => getScheduleByProgramHandler(e.value)}
								placeholder='Select'
							/>
						</div>
						{/* <div className='d-flex align-items-center'>
							<label className='me-2'>
								{MY_CALENDAR_CONSTANTS.SELECT_JOBSITE}
							</label>
							<Dropdown
								value={jobsite}
								optionLabel='jobsite.name'
								optionValue='id'
								options={jobSites}
								onChange={(e) => getScheduleByJobSiteHandler(e.value)}
								placeholder='Select'
							/>
						</div> */}
					</div>
				</div>
				<hr />
				<Calendar
					events={calendarEvents}
					eventClick={handleEventClick}
					intialView='dayGridMonth'
					datesSet={dateSetHandler}
					headerToolbar={headerToolbar}
				/>
			</div>
			{showScheduleModal && (
				<Modal
					className={'custom-modal'}
					onmodalClose={() => onModalHide()}
					modaldisplay={showScheduleModal}
					renderFooter={renderModalFooter()}
					headerLabel={renderScheduleModalHeader}
				>
					<table className='table table-striped small'>
						<thead>
							<tr>
								<th>{MY_CALENDAR_CONSTANTS.PART_NO}</th>
								<th className='text-end'>
									{MY_CALENDAR_CONSTANTS.NO_OF_PARTS}
								</th>
								<th className='text-end'>
									{MY_CALENDAR_CONSTANTS.TRUCKS_NEEDED}
								</th>
							</tr>
						</thead>
						<tbody>
							{eventDetail?.map((event, index) => (
								<tr key={index}>
									<td>{event.part_name}</td>
									<td className='text-end'>{event.pieces}</td>
									<td className='text-end'>{event.truck}</td>
								</tr>
							))}
						</tbody>
					</table>
					<div style={{ float: 'right' }} className='modal-title-small'>
						{'TOTAL:'} <span>{truckCountString ? truckCountString : ''} {MY_CALENDAR_CONSTANTS.TRUCKS}</span>
					</div>
				</Modal>
			)}
		</>
	);
};

export default MyCalendar;
