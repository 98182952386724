import { api } from '../../shared/lib/api';

class DashboardApi {
	async getDashboardGraph() {
		return await api.get(`api/v1.0/location/dashboardgraph`);
	}
	async getDashboardRecent5Programs(limit) {
		return await api.get(`api/v1.0/location/dashboardprograms/?limit=${limit}`);
	}
	async getDashboardCard() {
		return await api.get(`api/v1.0/location/dashboardcards`);
	}
	async getDashboardCalendar(date, month, year) {
		return await api.get(
			`api/v1.0/location/dashboardcalendar/?day=${date}&month=${month}&year=${year}`
		);
	}
}

export const dashboardApi = new DashboardApi();
