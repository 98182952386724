import { api } from "../../shared/lib/api";

class ShippingPlanApi {
    async postOrUpdateShippingPlan(id, data){
        return await api.put(`api/v1.0/location/Programsbase/${id}/`, data);
    }
    async deleteShippingPlan(id, data){
        return await api.patch(`api/v1.0/location/Programsbase/${id}/`, data);
    }
}

export const shippingPlanApi = new ShippingPlanApi();