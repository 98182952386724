import React, { useCallback, useContext, useEffect, useState } from 'react';
import AddNewJobsiteComponent from './add-new-jobsite-component';
import { Button } from 'primereact/button';
import LoaderContext from '../../shared/loader/loader-context';
import { SHARED_CONSTANTS } from '../../shared/shared-constants';
import { JOBSITES_CONSTANTS } from './job-sites-constant';
import { jobSiteApi } from './jobSiteApi';
import Modal from '../../shared/components/modal';

const JobSites = () => {
	let isAdmin = localStorage.getItem('isAdmin');
	isAdmin = typeof isAdmin === 'string' ? JSON.parse(isAdmin) : isAdmin;
	const ctx = useContext(LoaderContext);
	const [jobsites, setJobSites] = useState([]); //set all jobsites in an array
	const [selectedjobsite, setSelectedJobSite] = useState(); //to store selected job site to edit
	const [showDeleteModal, setShowDeleteModal] = useState(false); // show/hide delete Modal
	const [showJobsite, setShowJobsite] = useState(false); //to show edit/add job site form
	const [buttonDisable, setButtonDisable] = useState(false);

	// Get all Jobsite
	const fetchJobSites = useCallback(async () => {
		ctx.loadingHandler(true);
		const response = await jobSiteApi.getJobSites();
		ctx.loadingHandler(false);
		if (typeof response != 'undefined') {
			if (!response.errors) {
				setJobSites(response);
			} else {
				ctx.toasterHanlder(
					`${SHARED_CONSTANTS.ERROR}`,
					'',
					`${response.errors[0].message}`
				);
			}
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${SHARED_CONSTANTS.SOMETHING_WENT_WRONG}`
			);
		}
	}, []);

	useEffect(() => {
		fetchJobSites();
	}, [fetchJobSites]);

	// showJobsite Edit
	const showJobsiteHandler = (jobsite) => {
		setSelectedJobSite(jobsite);
		// //console.log("passing jobsite", jobsite)
		setShowJobsite(true);
	};
	//show modal on add new
	const addNewJobSiteHandler = () => {
		setSelectedJobSite();
		setShowJobsite(true);
	};

	// Close Jobsite Add/Edit Modal
	const jobSiteModalCloseHandler = () => {
		setShowJobsite(false);
		fetchJobSites(); //fetch job site after add/edit
		setSelectedJobSite(); // after deleting set selected Job Site to null
	};

	// showModal
	const showModalHandler = (jobsite) => {
		setSelectedJobSite(jobsite);
		setShowDeleteModal(true);
	};

	//to delete selected holiday
	const deleteJobSiteHandler = async () => {
		setButtonDisable(true);
		ctx.loadingHandler(true);
		const response = await jobSiteApi.deleteJobSite(selectedjobsite.id);
		ctx.loadingHandler(false);
		// //console.log("del", response);
		fetchJobSites(); //fetch Jobsites after delete
		setShowDeleteModal(false); //close modal on delete
		setSelectedJobSite(); // after deleting set selected Job Site to null
		if (!response?.errors) {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.SUCCESS}`,
				'',
				`${SHARED_CONSTANTS.ONDELETE_MSG}`
			);
		} else {
			ctx.toasterHanlder(
				`${SHARED_CONSTANTS.ERROR}`,
				'',
				`${response.errors[0].message}`
			);
		}
		setButtonDisable(false);
	};

	//Delete Modal Footer
	const renderDeleteModalFooter = () => {
		return (
			<div>
				<Button
					label={SHARED_CONSTANTS.ONDELETE_REJECT}
					onClick={() => setShowDeleteModal(false)}
					className='btn btn-secondary'
				/>
				<Button
					label={SHARED_CONSTANTS.ONDELETE_CONFIRM}
					onClick={deleteJobSiteHandler}
					autoFocus
					className='btn btn-yellow'
					disabled={buttonDisable}
				/>
			</div>
		);
	};

	return (
		<>
			{/* delete Modal */}
			{showDeleteModal && (
				<Modal
					className={'custom-modal'}
					onmodalClose={() => setShowDeleteModal(false)}
					modaldisplay={showDeleteModal}
					renderFooter={renderDeleteModalFooter()}
					headerLabel='Delete Job Site'
				>
					<p>{JOBSITES_CONSTANTS.DELETE_MSG}</p>
				</Modal>
			)}
			<div className='d-flex align-items-start flex-wrap'>
				<div className='page-header d-flex justify-content-between align-items-center py-2 flex-wrap flex-grow-1 me-3'>
					<div className='page-title me-4'>
						{JOBSITES_CONSTANTS.MODULE_NAME}
					</div>
					<div>
						{isAdmin && (
							<button
								className='btn btn-outline-primary btn-sm'
								onClick={addNewJobSiteHandler}
							>
								{SHARED_CONSTANTS.ADD_BTN}
							</button>
						)}
					</div>
					<div className='w-100'>
						<div className='table-responsive mt-2 custom-data-tables'>
							<table className='table'>
								<thead>
									<tr>
										<th>#</th>
										<th>{JOBSITES_CONSTANTS.JOBSITE_NAME_LABEL}</th>
										<th>{SHARED_CONSTANTS.DESCRIPTION_LABEL}</th>
										{isAdmin && (
											<th className='text-end'>
												{SHARED_CONSTANTS.ACTION_LABEL}
											</th>
										)}
									</tr>
								</thead>
								<tbody>
									{jobsites?.length === 0 && (
										<tr>
											<td colSpan='4'>{JOBSITES_CONSTANTS.NO_DATA_INFO}</td>
										</tr>
									)}
									{jobsites?.map((jobsite, index) => (
										<tr key={jobsite.id}>
											<td>{index + 1}</td>
											<td>{jobsite.name}</td>
											<td>{jobsite.description}</td>
											{isAdmin && (
												<td className='text-end'>
													<span
														className='me-3 pointer'
														title='Edit'
														onClick={() => showJobsiteHandler(jobsite)}
													>
														<i className='bi bi-pencil fs-6'></i>
													</span>
													<span
														title='Delete'
														className='pointer'
														onClick={() => showModalHandler(jobsite)}
													>
														<i className='bi bi-trash3 fs-6'></i>
													</span>
												</td>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className='form-wrapper py-2 px-2'>
					{showJobsite ? (
						<AddNewJobsiteComponent
							jobsite={selectedjobsite}
							onFetch={fetchJobSites}
							onCancel={jobSiteModalCloseHandler}
						/>
					) : null}
				</div>
			</div>
		</>
	);
};

export default JobSites;
