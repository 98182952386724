import { errorResponse } from './errorResponse';
import { handleResponse } from './reponseHandler';
import { API_ROOT } from './apiConfig';

// console.log('API ROOT', API_ROOT);

let requestOptions = {
	headers: {},
};

class Api {
	get = async (path) => {
		const token = localStorage.getItem('token');
		const url = API_ROOT + path;
		let response = await fetch(url, {
			headers: { Authorization: `Bearer ${token}` },
		})
			.then((response) => handleResponse(response))
			.catch((error) =>
			console.log({errorssss:error})
			//  errorResponse(error)
			);
		//console.log("get res", response);
		if(response?.status_code===403){
			localStorage.clear();
			window.location.href='/'
		}
		return response;
	};
	del = async (path) => {
		const token = localStorage.getItem('token');
		requestOptions.headers['Authorization'] = `Bearer ${token}`;
		const url = API_ROOT + path;
		requestOptions.method = 'DELETE';
		let response = await fetch(url, requestOptions)
			.then((response) => handleResponse(response))
			.catch((error) => errorResponse(error));
		//console.log(response);
		return response;
	};
	post = async (path, body) => {
		const token = localStorage.getItem('token');
		requestOptions.headers['Authorization'] = `Bearer ${token}`;
		const url = API_ROOT + path;
		requestOptions.headers['Content-Type'] = 'application/json';
		requestOptions.body = JSON.stringify(body);
		//console.log('requestOptions.body', requestOptions.body);
		requestOptions.method = 'POST';
		let response = await fetch(url, requestOptions)
			.then((response) => handleResponse(response))
			.catch((error) => errorResponse(error));
		return response;
	};
	delete = async (path, body) => {
		const token = localStorage.getItem('token');
		requestOptions.headers['Authorization'] = `Bearer ${token}`;
		const url = API_ROOT + path;
		requestOptions.headers['Content-Type'] = 'application/json';
		requestOptions.body = JSON.stringify(body);
		requestOptions.method = 'DELETE';
		let response = await fetch(url, requestOptions)
			.then((response) => handleResponse(response))
			.catch((error) => errorResponse(error));
		//console.log(response);
		return response;
	};
	patch = async (path, body) => {
		const url = API_ROOT + path;
		const token = localStorage.getItem('token');
		requestOptions.headers['Authorization'] = `Bearer ${token}`;
		requestOptions.headers['Content-Type'] = 'application/json';
		requestOptions.body = JSON.stringify(body);
		requestOptions.method = 'PATCH';
		let response = await fetch(url, requestOptions)
			.then((response) => handleResponse(response))
			.catch((error) => errorResponse(error));
		return response;
	};
	put = async (path, body) => {
		const url = API_ROOT + path;
		const token = localStorage.getItem('token');
		requestOptions.headers['Authorization'] = `Bearer ${token}`;
		requestOptions.headers['Content-Type'] = 'application/json';
		requestOptions.body = JSON.stringify(body);
		requestOptions.method = 'PUT';
		let response = await fetch(url, requestOptions)
			.then((response) => handleResponse(response))
			.catch((error) => errorResponse(error));
		return response;
	};
	postFile = async (path, body) => {
		const url = API_ROOT + path;
		const token = localStorage.getItem('token');
		requestOptions.headers['Authorization'] = `Bearer ${token}`;
		requestOptions.body = body;
		// requestOptions.headers = null;
		//console.log('requestOptions.body', requestOptions.body);
		requestOptions.method = 'POST';
		let response = await fetch(url, {
			headers: { Authorization: `Bearer ${token}` },
			method: 'POST',
			body: body,
		})
			.then((response) => handleResponse(response))
			.catch((error) => errorResponse(error));
		return response;
	};
}
export const api = new Api();
